import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../Alert";
import API from "../../Network/API";

//For Reassign MainTask
export async function reassignMainTask(MAINTASK, backlogs, activeStatus, currentDate, sprintTargetDate, state, dispatch, getUser, ideaId, moduleId, handleClose, MODULE, us_id) {

  // Date object
  const date = new Date();

  let currentDay = String(date.getDate()).padStart(2, '0');

  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

  let currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY 

  let currentDate1 = `${currentYear}-${currentMonth}-${currentDay}`;
  let te = new Date(currentDate1);
 

  dispatch(isLoading());
  if (!state.targetDate.value) {
    dispatch(isLoaded());
    Alert('error', 'Select Target date');
  }
  else if (new Date(state.targetDate.value) < te) {
    dispatch(isLoaded());
    Alert('error', 'Select valid date');

  }
  else if (state.targetDate.value > sprintTargetDate && backlogs === '1') {
    dispatch(isLoaded());
    Alert('error', `Target Date should not exceed ${MODULE} target date`);
  }
  else if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
   
    
    const temp= {
      crop: getUser.corp,
      action: "reassign",
      title: state.taskTitle.value,
      description: state.taskDescription.value,
      acceptanceCriteria: state.acceptanceCriteria.value,
      module_id: state.moduleId.value,
      idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
      //added_to: state.userSelected.value,
      added_to: state.userSelected === "" ? state.id.value : state.userSelected.value,
      added_by: getUser.empId,
      mainTaskId: state.taskId.value,
      storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
      priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
      "projectId":getUser.projectId,
      targetDate: state.targetDate.value,
      device_id: state.device_id,
      player_id: state.player_id,
    }

    const tempGroup = {
      crop: getUser.corp,
      action: "reassignGroup",
      title: state.taskTitle.value,
      description: state.taskDescription.value,
      acceptanceCriteria: state.acceptanceCriteria.value,
      module_id: state.moduleId.value,
      idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
      //added_to: state.userSelected.value,
      added_to: state.userSelected.from === 'group' ? getUser.empId : state.userSelected === "" ? state.id.value : state.userSelected.value,
      added_by: getUser.empId,
      mainTaskId: state.taskId.value,
      storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
      priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
      "projectId": getUser.projectId,
      targetDate: state.targetDate.value,
      device_id: state.device_id,
      player_id: state.player_id,
    }



  //--------user names are available in label---//
    // console.log('state user name:',state.userSelected.label);
 
    const user = state.users;
    const tempId = state.id.value;
   


    const fil = user.filter((user_) => {
      return user_.id === tempId
    })
    // ----getting the filtered user name--// 
    //console.log(fil);
 
  


    if (state.userSelected.from === 'group') {
      try {
        var response = await API.post("manage_userstories.php", tempGroup, {}, false);
     

        if (response.status === 'True') {
         
          //  Alert("success");
          addCommentUserStory(dispatch, getUser, state.taskId.value, `${MAINTASK} Reassigned to ` + (state?.userSelected?.label || fil[0]?.name), "5")
          // dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
          getUserIdWithEmails(state.userSelected.members_email, state, dispatch, getUser, handleClose, us_id)

        }
      } catch (error) {  
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    } else {
      try {
        var response1 = await API.post("manage_userstories.php", temp, {}, false);
        if (response1.status === 'True') {   
          //  Alert("success");
        addCommentUserStory(dispatch, getUser, state.taskId.value, `${MAINTASK} Reassigned to ` + (state?.userSelected?.label || fil[0]?.name), "5")
          // dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    }

    handleClose()
  } else {
    Alert("warning", "please give all the details")
  }
}

const getUserIdWithEmails = async (members_email, state, dispatch, getUser, handleClose, us_id) => {
  const emails = members_email.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g);
  // Remove duplicates by converting the array to a Set
  const uniqueEmailsSet = new Set(emails);
  // Convert Set back to array if needed
  const uniqueEmailsArray = [...uniqueEmailsSet];
  const filteredEmails = uniqueEmailsArray.filter(email => email !== getUser.userName);
  try {
    var response = await API.post("get_user.php", {
      action: "get_user_by_email",
      emailArray: filteredEmails
    }, {}, false);
    if (response.status === 'True') {
      response.data.map(item => {
        return createGroupMainTask(state, dispatch, getUser, item.empid, parseInt(us_id))
      })
      handleClose()
    }
  } catch (error) {
    console.log(error)
  }
}

export async function createGroupMainTask(state, dispatch, getUser, empId, us_id) {
  dispatch(isLoading());
  if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
    try {
      var response = await API.post("manage_userstories.php", {
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_to: empId,
        added_by: getUser.empId,
        module_id: state.moduleId.value,
        acceptanceCriteria: state.acceptanceCriteria.value,
        storyPoints: state.storySelected === "" ? '0' : state.storySelected,
        priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
        idea_id: state.epicSelected,
        "projectId": getUser.projectId,
        device_id: state.device_id,
        player_id: state.player_id,
        story_type: 'group',
        main_task_id: us_id

      }, {}, false);
      if (response.status === 'True') {
        console.log("Success")
      }
    } catch (error) {
      console.log("error")
    }
  }

  else {
    console.log("error")
  }
}