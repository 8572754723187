import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";
import { createMainTask } from "./createMainTask";
import { updateRoadblockTask } from "../../../UserModule/Backlogs/Services/updateRoadblockTask";

//For Add MainTask as template
export async function createMainTaskTemplate(state, dispatch, getUser, handleClose, MAINTASK_ADD, is_workflow = 0,props) {
  if (state.userSelected.from === 'group') {
    createMainTask(state, dispatch, getUser, handleClose, MAINTASK_ADD)
  } else {
    dispatch(isLoading());
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      try {
        var response = await API.post("manage_template.php", {
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: state.userSelected.value,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          "projectId": getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          is_workflow: is_workflow,
        }, {}, false);
        if (response.status === 'True') {
          if(props.combine_tasks === true){
            updateRoadblockTask(getUser, response.last_insert_id, props.data.selectedRoadblocks, props.handleClose)
          }else{
          Alert("success", MAINTASK_ADD);
          handleClose();
          }
        }
        else if (response.status === 'false' && response.message === "TemplateExisted") {
          dispatch(isLoaded());
          Alert("warning", "Template already existed!, Please change user template title");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    }

    else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }
}