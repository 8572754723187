import { isLoading, isLoaded, consolidatedToDoTo, consolidatedToDoBy, allTasks } from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";

export async function getConsolidatedTodoTo(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_todo_to",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedToDoTo(response.data))
    }
    else {
      dispatch(consolidatedToDoTo([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

export async function getConsolidatedTodoBy(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_todo_by",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedToDoBy(response.data))
    }
    else {
      dispatch(consolidatedToDoBy([]))
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}

export async function getAllTasks(dispatch, getUser, searchValue) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "mytasker_all_tasks",
      "searchValue": searchValue,
      "role": getUser.role

    }, {}, false);
    if (response.status === 'True') {
      dispatch(allTasks(response.data))
      dispatch(isLoaded());
    }
    else {
      dispatch(allTasks([]))
      dispatch(isLoaded());
    }
  } catch (error) {
    Alert("error", error.message);
    dispatch(isLoaded());
  }

}

export async function getArchiveAllTasks(dispatch, getUser, searchValue) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "archive_all_tasks",
      "searchValue": searchValue,
      "role": getUser.role,
      "projectId":getUser.projectId,

    }, {}, false);
    if (response.status === 'True') {
      dispatch(allTasks(response.data))
      dispatch(isLoaded());
    }
    else {
      dispatch(allTasks([]))
      dispatch(isLoaded());
    }
  } catch (error) {
    Alert("error", error.message);
    dispatch(isLoaded());
  }

}