import {
  isLoaded,
  isLoading,
} from "../actions";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "./addCommentUserStory";
import { getCurrentSprintUserStories } from "./getCurrentSprintUserStories";
import Alert from "../../../Common/Alert";

//Update the Task Status
export async function updateUserStory(
  dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING
) {

  try {
    const response = await API.post(
      "getUpdateSprint.php",
      {
        action: "getTaskInfoWithId",
        story_us_id: userStoryId,
        crop: getUser.corp,
      },
      {},
      false
    );
    if (response.status === "True") {
      callToCheckGroupTask(response.data[0].us_id, dispatch,
        getUser,
        userStoryId,
        sprintId,
        handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
    } else {

    }
  } catch (error) {
    console.log("error", error.message);
  }

}

async function update(
  dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING
) {
  dispatch(isLoading());

  try {
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "update",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    if (response.status === "True") {
      getCurrentSprintUserStories(dispatch, getUser, sprintId);
      addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
    } else {
      const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
      Alert('warning', warning)
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
  handleClose();
}

async function updateRoadblock(
  dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING
) {
  dispatch(isLoading());

  try {
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "updateRoadblock",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    if (response.status === "True") {
      getCurrentSprintUserStories(dispatch, getUser, sprintId);
      addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
    } else {
      const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
      Alert('warning', warning)
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
  handleClose();
}

const callToCheckGroupTask = (id, dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING) => {
  API.post("getUpdateSprint.php", {
    story_us_id: id,
    action: "get_group_task_info",
  })
    .then(function (response) {
      if (response.status === 'True') {
        if (response.data.length > 0) {
          response.data.map(x => {
            return update(dispatch, getUser, x.story_id, x.sprint_id, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
          })
          update(dispatch,
            getUser,
            userStoryId,
            sprintId,
            handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
          // let isCompleted = response.data.some(obj => obj.complete_status === "completed")
          // if (!isCompleted) {
          //   handleClose();
          //   Alert('warning', "You can't update until your dependency group task completed");
          // } else {
          //   update(dispatch,
          //     getUser,
          //     userStoryId,
          //     sprintId,
          //     handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
          // }
        }
      } else {
        callToCheckRoadblock(id, dispatch,
          getUser,
          userStoryId,
          sprintId,
          handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
      }
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function callToCheckRoadblock(
  id, dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING
) {

  try {
    const response = await API.post(
      "getUpdateSprint.php",
      {
        action: "getTaskInfoWithRoadblock",
        story_us_id: id,
        crop: getUser.corp,
      },
      {},
      false
    );
    if (response.status === "True") {
      if (response.message === 'success') {
        response.data.map(item => {
          return updateRoadblock(dispatch,
            getUser,
            item.story_id,
            sprintId,
            handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
        })
        update(dispatch,
          getUser,
          userStoryId,
          sprintId,
          handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
      } else {
        update(dispatch,
          getUser,
          userStoryId,
          sprintId,
          handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
      }
    }
  } catch (error) {
    console.log("error", error.message);
  }

}