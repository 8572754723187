import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import { useSelector } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
import { getSubStringId } from '../../Common/SubStringConvert';
import { useWebProperties } from '../../Common/webProperties';
import { getDecimalHours } from '../../Common/convertDecimalHoursToTime';
import { getOnlyTimeZone } from '../../Common/showOnlyTimeZone';
import convertPSTtoLocalTime from '../../Common/convertPSTtoLocalTime';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '80vh',
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    },
}));

export default function UserProjectInfo(props) {
    const [result, setResult] = useState([])
    const [message, setMessage] = useState("")
    const classNames = useStyles();
    const { ASSIGNED_STORY_POINTS, MAINTASKNAME, MAINTASKS, PROJECT, PROJECTS_INVOLVED, STORY_POINTS } = useWebProperties();
    const title = props.data.action === PROJECTS_INVOLVED ? PROJECTS_INVOLVED.concat(" - ", props.data.email) : 
    props.data.action==='getTotalTicketsReport' || props.data.action==='responseTime' || props.data.action==='totalResolved' || props.data.action==='totalPending' ? props.data.column_name : props.data.project_name || props.data.name;
    const properties = useSelector(state => state.landingReducer.properties);
    async function Network(api, data, state) {
        setResult([])
        try {
            const response = await ReportsAPI.post(`/${api}`, data, {}, false)
            state(response)
        }
        catch (error) {
            setMessage(error.message)
            console.log('error', error.message)
        }
    }

    async function NetworkSupportProjectReport(api,data,state) {
        try {
            const response = await ReportsAPI.post(`/${api}`, data, {}, false)
            setResult([])
            state(response)
        }
        catch (error) {
            setMessage(error.message)
            console.log('error', error.message)
        }
    }
    useEffect(() => {
        if (props.data.action === 'userReports') {
            Network('projectUserReport', { project_id: props.data.project_id }, setResult)
        }
        if (props.data.action === 'getProjectActiveStoryUsers') {
            Network('getProjectActiveStoryUsers', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === 'getProjectWorkingHours') {
            Network('getProjectWorkingHours', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === 'getIndividualUserstory') {
            Network('getIndividualUserstory', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUserTimeSheets") {
            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getAllStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getOnlyStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUtilizationStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUtilizationHours") {
            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUserProjects") {
            Network('getUserProjectsById', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "projectName") {
            Network('projectName', { email: props.data.email }, setResult)
        }
        if (props.data.action === "getTotalTicketsReport"){
            NetworkSupportProjectReport('getTotalTicketsReport',{project_id: props.data.project_id,filter:props.data.dateFilter},setResult)
        }
        if (props.data.action === "responseTime"){
            NetworkSupportProjectReport('getResponseTimeReport',{project_id: props.data.project_id,filter:props.data.dateFilter},setResult)
        }
        if (props.data.action==="totalResolved" || props.data.action === "totalPending" ){
            NetworkSupportProjectReport('getPendingCompletedReport',{project_id: props.data.project_id,filter:props.data.dateFilter},setResult)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (result.length > 0) {
            $(document).ready(function () {
                window.$('#user-info-table').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [result])

    const modCol5Array = ['getAllStories', 'getIndividualUserstory', 'getUserTimeSheets', 'getUtilizationStories', 'getUtilizationHours', 'getUserProjects', 'getOnlyStories']

    const modalBoox = (modCol5Array.includes(props.data.action)) ? "modal-content  p-2" : "modal-content  p-2"
    const getCategory= (taskDescription)=> {
        const lowerCaseDescription = taskDescription.toLowerCase();
        
        
        if (lowerCaseDescription.includes('android')) {
            return 'Android';
        } else if (lowerCaseDescription.includes('ios')) {
            return 'IOS';
        } else if (lowerCaseDescription.includes('web')) {
            return 'Web';
        } else if(lowerCaseDescription.includes('mobile')){
            return 'Mobile'
        } 
        else {
            return 'Web';
        }
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center" style={{ height: '80vh' }}>
                            <div className={modalBoox} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <div className="column">
                                        {props.data.action==='getTotalTicketsReport' || props.data.action==='responseTime' || props.data.action==='totalResolved' || props.data.action==='totalPending' 
                                        ? 
                                        (<h6 class="modal-title p-1">{title}</h6>) 
                                        :
                                        (<h6 class="modal-title p-1">{title} - {props.data.column_name}</h6>)
                                    }
                                    </div>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ overflow: 'auto' }}>
                                    <div className="overflow-auto" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                                        {props.data.action === 'Projects Involved' && (result.length > 0 ?
                                            result.map(({ project_name }, index) => {
                                                return (
                                                    <p className="text-left" > <h6 className="pt-1" style={{ color: 'grey', textTransform: "capitalize" }}>{index + 1}. {project_name}</h6></p>
                                                )
                                            }) : <p>{message}</p>)}
                                        {props.data.action === 'userReports' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User</th>
                                                        <th>Active Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ working_status, fullname }, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                    <td style={{ color: working_status === 'Active' ? 'green' : 'red' }}>{working_status}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUserProjects' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>Project</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ project_name }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}

                                        {props.data.action === 'getProjectWorkingHours' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User</th>
                                                        <th>Working Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ hours, fullname }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                    <td>{getDecimalHours(hours)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table> : <p>{message}</p>)}
                                        {props.data.action === 'getIndividualUserstory' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1 rwd-table' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User</th>
                                                        <th>{MAINTASKNAME}</th>
                                                        <th>Working Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ story_title, fullname, working_hours }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                    <td>{story_title}</td>
                                                                    <td>{getDecimalHours(working_hours)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table> : <p>{message}</p>)}
                                        {props.data.action === 'getUserTimeSheets' && (result.length > 0 ?

                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>{PROJECT}</th>
                                                        <th>{MAINTASKS || properties.USER_STORIES}</th>
                                                        <th>Date</th>
                                                        <th>Start time</th>
                                                        <th>End time</th>
                                                        <th>Estimated Hours</th>
                                                        <th>Working Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ story_title, total_working_hours, project_name, updated_date, us_id, start_time, end_time, time_data, story_points }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    <td>{moment(updated_date).format('MM/DD/YYYY')}</td>
                                                                    <td>{start_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss a')}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{end_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{(moment(end_time).format('MM/DD/YYYY hh:mm:ss a'))}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{story_points}</td>
                                                                    <td>{getDecimalHours(total_working_hours)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >


                                            : <p>{message}</p>)}
                                        {props.data.action === 'getProjectActiveStoryUsers' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>Users</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ fullname }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getOnlyStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>{PROJECT}</th>
                                                        <th>{MAINTASKS}</th>
                                                        <th>{ASSIGNED_STORY_POINTS}</th>
                                                        <th>Complete Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, us_id, story_points }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    <td>{story_points}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getAllStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>{PROJECT}</th>
                                                        <th>{MAINTASKS}</th>
                                                        <th>Complete Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, us_id }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUtilizationStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>{PROJECT}</th>
                                                        <th>{MAINTASKS}</th>
                                                        {/* <th>{KANBAN}</th> */}
                                                        <th>{STORY_POINTS}</th>
                                                        <th>{MAINTASKS} Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, story_points, kanban_status, us_id }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    {/* <td style={{ color: kanban_status === '0' ? "red" : "green" }}>{kanban_status === "0" ? "NO" : "YES"}</td> */}
                                                                    <td>{story_points}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUtilizationHours' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>{PROJECT}</th>
                                                        <th>{MAINTASKS}</th>
                                                        {/* <th>{KANBAN}</th> */}
                                                        <th>Date</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>Estimated Hours</th>
                                                        <th>Working Hours</th>
                                                        {/* <th>{MAINTASKS} Status</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, working_hours, updated_date, kanban_status, us_id, total_working_hours, start_time, end_time, story_points }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    {/* <td style={{ color: kanban_status === '0' ? "red" : "green" }}>{kanban_status === "0" ? "NO" : "YES"}</td> */}
                                                                    <td>{moment(updated_date).format('MM/DD/YYYY')}</td>
                                                                    <td>{start_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss a')}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{end_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{(moment(end_time).format('MM/DD/YYYY hh:mm:ss a'))}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{story_points}</td>
                                                                    <td>{getDecimalHours(total_working_hours)}</td>
                                                                    {/* <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action==='getTotalTicketsReport' && (result.length>0 ? 
                                        <table className='table table-bordered mb-1' id="user-info-table">
                                            <thead>
                                                <tr>
                                                    <th>Created Date & Time</th>
                                                    <th>Categories</th>
                                                    <th>Main Ticket into RB</th>
                                                    <th>Generated Ticket in TODO</th>
                                                    <th>Support Ticket Details</th>
                                                    <th>Assigned by</th>
                                                    <th>Assigned to</th>
                                                    <th>Reassigned to Developer</th>
                                                    <th>Generated Ticket Status</th>
                                                    <th>Main Ticket Status</th>
                                                    <th>Priority/Severity</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    result.map((info,index)=>{
                                                        return (
                                                           <tr key={index}>
                                                              <td>{convertPSTtoLocalTime(info.date)} </td>
                                                              <td>{info.support_tkt_details ? getCategory(info.support_tkt_details):'NA'}</td>
                                                              <td>{info.mtrb}</td>
                                                              <td>{info.gtodo ? info.gtodo : 'NA'}</td>
                                                              <td>{info.support_tkt_details ? info.support_tkt_details : info.name}</td>
                                                              <td>{info.assigned_by_name}</td>
                                                              <td>{info.assigned_to_name}</td>
                                                              <td>{info.reassign_developer ? info.reassign_developer : 'NA'}</td>
                                                              <td>{info.gen_tkt_status ? info.gen_tkt_status : 'NA'}</td>
                                                              <td>{info.main_tkt_status}</td>
                                                              <td>{info.priority_level ? info.priority_level : 'NA'}</td>
                                                            </tr>

                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : <p>{message}</p>
                                        )}
                                        {props.data.action==='responseTime' && (result.length>0 ? 
                                        <table className='table table-bordered mb-1' id="user-info-table">
                                            <thead>
                                                <tr>
                                                    <th>Task Id</th>
                                                    <th>Ticket Generated Date & Time</th>
                                                    <th>Name</th>
                                                    <th>Assigned by</th>
                                                    <th>Assigned to</th>
                                                    <th>Responded By</th>
                                                    <th>Initial Response Time(Support Team)</th>
                                                    <th>Response Time(User)</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    result.map((info,index)=>{
                                                        return (
                                                            <tr key={index}>
                                                              <td>{info.task_id} </td>
                                                              <td>{info.created_date ? convertPSTtoLocalTime(info.created_date):"NA"}</td>
                                                              <td>{info.name ? info.name : "NA"}</td>
                                                              <td>{info.assigned_by_name}</td>
                                                              <td>{info.assigned_to_name}</td>
                                                              <td>{info.messaged_by_name ? info.messaged_by_name : "NA"}</td>
                                                              <td>{info.support_resp_time ? `${info.support_resp_time} secs`:'Not Responding'}</td>
                                                              <td>{info.user_resp_time ? `${info.user_resp_time} secs`:'Not Responding'}</td>
                                                            </tr>

                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : <p>{message}</p>
                                        )}
                                        {props.data.action==='totalResolved' && (result.length>0 ? 
                                        <table className='table table-bordered mb-1' id="user-info-table">
                                            <thead>
                                                <tr>
                                                    <th>Task Id</th>
                                                    <th>Created Date & Time</th>
                                                    <th>Name</th>
                                                    <th>Assigned by</th>
                                                    <th>Assigned to</th>
                                                    <th>Completed Date & Time</th>
                                                    <th>Ticket Type</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    result.flatMap((info,index)=>{
                                                        const elements=[];
                                                        if(info.main_tkt_status==='completed'){
                                                            elements.push(
                                                                <tr key={index}>
                                                                          <td>{info.mtrb} </td>
                                                                          <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                          <td>{info.name}</td>
                                                                          <td>{info.assigned_by_name}</td>
                                                                          <td>{info.assigned_to_name}</td>
                                                                          <td>{convertPSTtoLocalTime(info.main_tkt_end_date)}</td>
                                                                          <td>Main Ticket</td>
                                                                        </tr>
                                                            );
                                                        }

                                                        if(info.gtodo && info.gen_tkt_status==='completed'){
                                                            elements.push(
                                                                <tr key={`${index}-gtodo`}>
                                                                     <td>{info.gtodo} </td>
                                                                     <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                     <td>{info.name}</td>
                                                                     <td>{info.assigned_to_name}</td>
                                                                     <td>{info.reassign_developer}</td>
                                                                     <td>{convertPSTtoLocalTime(info.blocked_tkt_end_date)}</td>
                                                                     <td>Blocked Ticket</td>
                                                                </tr>
                                                            );
                                                        }
                                                        return elements;
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : <p>{message}</p>
                                        )}
                                        {props.data.action==='totalPending' && (result.length>0 ? 
                                        <table className='table table-bordered mb-1' id="user-info-table">
                                            <thead>
                                                <tr>
                                                    <th>Task Id</th>
                                                    <th>Ticket Generated Date & Time</th>
                                                    <th>Name</th>
                                                    <th>Assigned by</th>
                                                    <th>Assigned to</th>
                                                    <th>Ticket Type</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                    result.flatMap((info,index)=>{
                                                        const elements=[];
                                                        if(info.main_tkt_status==='pending'){
                                                            elements.push(
                                                                <tr key={index}>
                                                                          <td>{info.mtrb} </td>
                                                                          <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                          <td>{info.name}</td>
                                                                          <td>{info.assigned_by_name}</td>
                                                                          <td>{info.assigned_to_name}</td>
                                                                          <td>Main Ticket</td>
                                                                        </tr>
                                                            );
                                                        }

                                                        if(info.gtodo && info.gen_tkt_status==='pending'){
                                                            elements.push(
                                                                <tr key={`${index}-gtodo`}>
                                                                     <td>{info.gtodo} </td>
                                                                     <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                     <td>{info.name}</td>
                                                                     <td>{info.assigned_to_name}</td>
                                                                     <td>{info.reassign_developer}</td>
                                                                     <td>Blocked Ticket</td>
                                                                </tr>
                                                            );
                                                        }
                                                        return elements;
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : <p>{message}</p>
                                        )}
                                    </div>
                                </div>


                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}