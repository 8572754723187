import React, { useEffect, useReducer, useState } from 'react';
import { accountReducer, initialState } from './accountReducer';
import AdminTopNav from '../Utility/TopNav';
import AdminSideBar from '../Utility/SideNav';
import Switch from 'react-switch';

import { useWebProperties } from '../../Common/webProperties';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import { getAccountPermissions } from './Services/getAccountPermissions';
import EditAccount from './editAccount';


export default function AccountProfile() {
  const { params: { accountId } } = useRouteMatch();
  const [state, dispatch] = useReducer(accountReducer, initialState)
  const [collapse, setCollapse] = useState(false);
  const [open, setOpen] = useState({ status: false, index: 0 })
  const [info, setInfo] = useState();
  const { CONTACT, CLIENT_NAME, EMAIL, USERNAME, NO_OF_PROJECTS, NO_OF_USERS } = useWebProperties();

  useEffect(() => {
    getAccountPermissions(dispatch, accountId);
    // eslint-disable-next-line
  }, [accountId])
  const collapseAll = () => {
    setCollapse(!collapse);
  };

  const handleOpen = (index, action) => {
    if (action === "Add") {
      setOpen({ status: true, action: action });
    } else {
      setOpen({ status: true, index: index, action: action });
      var info = {
        id: state.accountFeaturePermissions[0].user_id,
        employeeId: state.accountFeaturePermissions[0].user_id,
        name: state.accountFeaturePermissions[0].username,
        action: action,
        email: state.accountFeaturePermissions[0].email,
        mobile: state.accountFeaturePermissions[0].mobile,
        userName: state.accountFeaturePermissions[0].username,
        clientname: state.accountFeaturePermissions[0].client_name,
        no_of_projects: state.accountFeaturePermissions[0].number_of_projects,
        no_of_users: state.accountFeaturePermissions[0].number_of_users,
        workingStatus: state.accountFeaturePermissions[0].workingStatus,
      }
      setInfo(info)
    }
  };
  const handleClose = () => {
    setOpen({ status: false, index: 0 });
    getAccountPermissions(dispatch, accountId);
  };
  const handleModalClose = () => {
    setOpen({ status: false, index: 0 });
  }

  return (
    <div className="container-scroller">
      <AdminTopNav />
      <div className="container-fluid page-body-wrapper">
        <AdminSideBar />
        <div className="main-panel">
          <div className="mt-2">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="justify-content-between row">
                    <h4 className="card-title ml-2">{state.accountFeaturePermissions.length > 0 && state.accountFeaturePermissions[0].client_name.toUpperCase()}</h4>
                  </div>
                  <li className="nav-item" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    padding: '.5rem',
                  }}>
                    <button style={{ padding: '3px 12px', background: 'transparent' }}>Export</button>
                    <Switch
                      checked={collapse}
                      onChange={() => collapseAll()}
                      handleDiameter={27}
                      onColor="#CD5C5C"
                      offColor="#008000"
                      offHandleColor="#fff"
                      onHandleColor="#fff"
                      height={30}
                      width={130}
                      borderRadius={6}
                      activeBoxShadow="0px 0px 1px 2px #fffc35"
                      uncheckedIcon={
                        <div style={{ paddingTop: 5, marginLeft: -47, width: 85 }}>
                          <b style={{ color: 'white' }} >Expand All</b>
                        </div>
                      }
                      checkedIcon={
                        <div style={{ paddingTop: 5, marginLeft: 10, width: 85 }}>
                          <b style={{ color: 'white' }}>Collapse All</b>
                        </div>
                      }
                      className="react-switch"
                      id="small-radius-switch"
                    />
                  </li>
                  <li className="nav-item p-2">
                    <div
                      activeStyle={{
                        fontWeight: "bold",
                        color: "#007bff",
                      }}
                      class="btn dropdown-toggle"
                      onClick={() => collapseAll()}
                      style={{
                        fontWeight: "bold",
                        display: 'flex',
                        padding: '0.5rem 1rem',
                        justifyContent: 'space-between',
                        border: '1px solid'
                      }}
                      className="nav-link" >
                      <span className="menu-title pl-0" style={{ fontWeight: "bold" }}>Client Profile </span>
                      <i class="material-icons" style={{ cursor: 'pointer' }}>{collapse ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                    </div>
                  </li>
                  {state.accountFeaturePermissions.length > 0 && collapse && state.accountFeaturePermissions.map((list, index) => {
                    return (
                      <li className="nav-item" style={{ display: 'grid', gridTemplateColumns: '95% 5%'}}>
                        <form className="forms-sample p-4">
                          <div className="form-group row" >
                            <label className="col-sm-3 col-form-label">{CLIENT_NAME}</label>
                            <div className="col-sm-9">
                              <label className="col-sm-6 col-form-label">{list.client_name.toUpperCase()}</label>
                            </div>
                          </div>
                          <div className="form-group row" >
                            <label className="col-sm-3 col-form-label">Category</label>
                            <div className="col-sm-9">
                              <label className="col-sm-6 col-form-label">{list.category_name}</label>
                            </div>
                          </div>
                          <div className="form-group row" >
                            <label className="col-sm-3 col-form-label">{USERNAME}</label>
                            <div className="col-sm-9">
                              <label className="col-sm-6 col-form-label">{list.username}</label>
                            </div>
                          </div>
                          <div className="form-group row" >
                            <label className="col-sm-3 col-form-label">{EMAIL}</label>
                            <div className="col-sm-9">
                              <label className="col-sm-6 col-form-label">{list.email}</label>
                            </div>
                          </div>
                          <div className="form-group row" >
                            <label className="col-sm-3 col-form-label">{CONTACT}</label>
                            <div className="col-sm-9">
                              <label className="col-sm-6 col-form-label">{list.mobile}</label>
                            </div>
                          </div>
                          <div className="form-group row" >
                            <label className="col-sm-3 col-form-label">{NO_OF_PROJECTS}</label>
                            <div className="col-sm-9">
                              <label className="col-sm-6 col-form-label">{list.number_of_projects}</label>
                            </div>
                          </div>
                          <div className="form-group row" >
                            <label className="col-sm-3 col-form-label">{NO_OF_USERS}</label>
                            <div className="col-sm-9">
                              <label className="col-sm-6 col-form-label">{list.number_of_users}</label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <button className='btn-success ml-2' disabled> UPGRADE</button>
                          </div>
                        </form>
                        <span onClick={() => handleOpen(index, "Edit")}
                          style={{ marginTop: '10px' }}>
                          <img src="images/common/edit_Icon.png" style={{ width: '18px', height: '18px' }} alt="" />
                        </span>
                      </li>
                    )
                  })
                  }
                  <li className="nav-item p-2">
                    <div
                      activeStyle={{
                        fontWeight: "bold",
                        color: "#007bff",
                      }}
                      class="btn dropdown-toggle"
                      onClick={() => collapseAll()}
                      style={{
                        fontWeight: "bold",
                        display: 'flex',
                        padding: '0.5rem 1rem',
                        justifyContent: 'space-between',
                        border: '1px solid'
                      }}
                      className="nav-link" >
                      <span className="menu-title pl-0" style={{ fontWeight: "bold" }}>Client Dashboard </span>
                      <i class="material-icons" style={{ cursor: 'pointer' }}>{collapse ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                    </div>
                  </li>
                  <li className="nav-item p-2">
                    <div
                      activeStyle={{
                        fontWeight: "bold",
                        color: "#007bff",
                      }}
                      class="btn dropdown-toggle"
                      onClick={() => collapseAll()}
                      style={{
                        fontWeight: "bold",
                        display: 'flex',
                        padding: '0.5rem 1rem',
                        justifyContent: 'space-between',
                        border: '1px solid'
                      }}
                      className="nav-link" >
                      <span className="menu-title pl-0" style={{ fontWeight: "bold" }}>Client Activity Log </span>
                      <i class="material-icons" style={{ cursor: 'pointer' }}>{collapse ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                    </div>
                  </li>
                  <li className="nav-item p-2">
                    <div
                      activeStyle={{
                        fontWeight: "bold",
                        color: "#007bff",
                      }}
                      class="btn dropdown-toggle"
                      onClick={() => collapseAll()}
                      style={{
                        fontWeight: "bold",
                        display: 'flex',
                        padding: '0.5rem 1rem',
                        justifyContent: 'space-between',
                        border: '1px solid'
                      }}
                      className="nav-link" >
                      <span className="menu-title pl-0" style={{ fontWeight: "bold" }}>Client Activity Reports </span>
                      <i class="material-icons" style={{ cursor: 'pointer' }}>{collapse ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                    </div>
                  </li>
                </div>
                {
                  open.action === "Edit" ? <EditAccount open={open.status} handleClose={handleClose} data={info} handleModalClose={handleModalClose}
                  /> : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}