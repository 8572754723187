////common/ChatMainTask/Services/getGroupMembersList (READ)
import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";
import {  groupList, groupName } from "../actions";


export async function getGroupMembersList(dispatch, getUser, data) {
  const projectId = getUser.projectId;
  const project_name = getUser.corp;
  
  dispatch(isLoading());
  try {
    // var response = await API.post("", {
    var response = await API.post("user_story_details.php", {
      corp_code: project_name,
      action: data.action,
      groupId: data.subtask_id,
      projectId: projectId,
    }, {}, false);
    //Getting the Group details for Subtask start
    if(response.status === 'True' && response.task === 'workflow_task') {
      var result = {
        assigned_to: response.assigned_to,
        assigned_by: response.assigned_by,
        project_name: response.project_name,
        story_title: response.task_title,
        data: response.data.map(function (item) {
          return {
            full_name: item.fullname,
            full_role: item.role,
            email: item.email,
            assigned_to_email: item.assigned_to_email,
            assigned_by_email: item.assigned_by_email,
            display_name: item.display_name
          };
        })
      };

      dispatch(groupList(result))
      dispatch(groupName(response.story_title))
    }
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}