import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";
import { isLoaded, isLoading } from "../actions";


//Check Existing Employee
export async function createEmployee(state, dispatch, getUser, handleClose, APP_NAME, SCRUM_MASTER, PRODUCT_OWNER) {
    // const [, extension] = (getUser.userName).split('@')
    // const [, extension1] = (state.email.value).split('@')
  
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var string_length = 8;
    var pass_code = '';
    for (var i=0; i<string_length; i++) {
        var num = Math.floor(Math.random() * chars.length);
        pass_code += chars.substring(num,num+1);
    }
    pass_code='agile24x7';
    dispatch(isLoading());
    //Removed accessing from domain based restriction
    // if(extension !== extension1){
    //   Alert("warning", "please add suitable corporate mail id")
    //   dispatch(isLoaded());
    // }
    // else 
    if ((state.email.value !== "" && state.fullName.value !== ""  && !(state.fullName.errorStatus || state.email.errorStatus ))) {
      try {
        var response = await API.post("manage_user.php", {
          empId: state.empId.value,
          fullname: state.fullName.value,
          email: state.email.value,
          username: state.email.value,
          mobile: state.mobile.value,
          action: "check_user",
          corp: getUser.corp,
          projectId:getUser.projectId,
          password: pass_code, //state.password.value,
          // team: state.roleSelected.value,
          // designation: state.designationSelected.value,
          // reportingManager: state.reportingManagerSelected.value,
          // functionalManager: state.functionalManagerSelected.value,
          userType: state.userType.value,
          user_status: state.userStatus.value,
          created_by: getUser.empId,
          created_name: getUser.userName, //logged in user name
          url: Client_URL,
          appName: APP_NAME,
          support_role: state.supportRole.value
        }, {}, false);
          // console.log(response.status)
        if (response.status === "True") {
          Alert("success", response.message)
          handleClose()
          dispatch(isLoaded());
          // addEmployee(state, dispatch, getUser, handleClose)
        } else if(response.status === "False1") {
          Alert("warning", `User can add one ${SCRUM_MASTER} and one ${PRODUCT_OWNER}.`);
          handleClose()
          dispatch(isLoaded());
        }
         else {
          Alert("warning", response.message)
          handleClose()
          dispatch(isLoaded());
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    } else {
      Alert("warning", "please fill all the details")
      dispatch(isLoaded());
    }
    handleClose(); // Close the modal
    // window.location.reload(); // Reload the page
  
  }