import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../../TasksModals/actions";
import { addCommentUserStory } from "../../TasksModals/Services/addCommentUserStory";


//Verify MainTask
export async function verifyMainTask(dispatch,getUser,mainTaskId,handleClose,handleModalClose, MAINTASK_VERIFY, MAINTASK_VERIFY_FAIL) {

    dispatch(isLoading());
    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        userStoryId: mainTaskId,
        action: "verify",
        emp_id: getUser.empId,
      },{},false);
      if(response.status === 'True') {
        Alert("success", MAINTASK_VERIFY)
      
        addCommentUserStory(dispatch, getUser, mainTaskId, `${MAINTASK_VERIFY}`, "5")

        handleClose()
      }else if( response.status === "false")
        {
       
        handleModalClose()
        Alert("warning", MAINTASK_VERIFY_FAIL )
      }else{

      }  
    } catch (error) {
      Alert('error',error.message);
      // handleClose()
    }
    dispatch(isLoaded());
  }