import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import * as actions from './actions';
import { initialState, roadblockReducer } from './roadblockReducer';
import API from '../Network/API';
import Alert from '../Alert';
import { getRoadBlock } from '../../UserModule/UserDashboard/Services/getRoadBlock';
import Select from 'react-select';
import { priority } from '../Json/priority';
import { severity } from '../Json/severity';
//Add Comments for UserStory  Where We Have and Who can Add comments for that By GNK--> 01 version 1.0.6
import { addCommentUserStory } from '../../UserModule/Modules/Services/addCommentUserStory';
import { addTasksComment } from '../../UserModule/Backlogs/Services/addTasksComment';
import { tasksReducer as backlogReducer, initialState as backlogState } from '../../UserModule/Backlogs/tasksReducer';
import { getPending } from '../../UserModule/Backlogs/Services/getPending';
import { getActiveSprints } from '../../UserModule/Backlogs/Services/getActiveSprints';
import { getSubStringId } from '../SubStringConvert';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//To add the roadblock from subtask
export default function RoadBlock(props) {

    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(roadblockReducer, initialState)
    const [bState, backlogDispatch] = useReducer(backlogReducer, backlogState);
    const [selectedFromTask, setSelectedFromTask] = useState(false)
    const [loader, setLoader] = useState(false)

    const classNames = useStyles();
    // eslint-disable-next-line 
    const assignedRoadBlock = async () => {
        var message = props.data.message + " with Description : " + state.roadblockTitle.value
        if (state.roadblockTitle.value !== "" && !(state.roadblockTitle.errorStatus)) {
            setLoader(true)
            try {
                var response = await API.post("manage_roadblocks.php", {
                    crop: getUser.user.corp,
                    action: 'assign_roadblock_from_task',
                    subTaskId: props.data.taskId,
                    newTaskId: state.taskSelected.data.userstory_id,
                    assignedBy: getUser.user.empId,
                    assignedTo: state.taskSelected.data.assignedTo,
                    prioritySelected: state.taskSelected.data.priorityLevel,
                    severitySelected: '1',
                    // module_id: moduleId,
                    acceptanceCriteria: state.taskSelected.data.acceptanceCriteria,
                    storyPoints: state.taskSelected.data.storyPoints,
                    title: state.taskSelected.data.tasktitle,
                    roadBlockDescription: state.taskSelected.data.tasktitle !== state.roadblockTitle.value ? state.roadblockTitle.value : state.taskSelected.data.tasktile,
                    description: state.taskSelected.data.taskdescription,
                    projectId: state.taskSelected.data.project_id
                }, {}, false);
                if (response.status === "True") {
                    addCommentUserStory(dispatch, getUser.user, props.data.taskId, message, "4") //Add Comments for UserStory  Where We Have and Who can Add comments for that By GNK--> 01 Version 1.0.6
                    getRoadBlock(dispatch, getUser.user)
                    Alert("success", response.message)
                } else if (response.status === 'false' && response.message === "RoadblockExisted") {
                    setLoader(false)
                    Alert("warning", "RoadBlock already existed!");
                }
            } catch (error) {
                console.log(error)
                setLoader(false)
            }
        } else {
            Alert("warning", "please fill all fields");
            setLoader(false)
        }
        props.handleClose();
    }

    const addRoadBlock = async (handleClose) => {
        var message = props.data.message + " with Description : " + state.roadblockTitle.value
        if (state.roadblockTitle.value !== "" && !(state.roadblockTitle.errorStatus)) {
            setLoader(true)
            try {
                var response = await API.post("manage_roadblocks.php", {
                    action: "insert",
                    crop: getUser.user.corp,
                    subTaskId: props.data.taskId,
                    roadBlockDescription: state.roadblockTitle.value,
                    empId: getUser.user.empId,
                    prioritySelected: state.prioritySelected.value === "" ? '0' : state.prioritySelected.value,
                    severitySelected: state.severitySelected.value === "" ? '0' : state.severitySelected.value,
                    projectId: (props.data.project_id || getUser.user.projectId),
                    moduleId: props.data.sprintId,
                    kanban_status: props.data.kanban_status === "1" ? 1 : 0
                }, {}, false);
                if (response.status === "True") {
                    addCommentUserStory(dispatch, getUser.user, props.data.taskId, message, "4") //Add Comments for UserStory  Where We Have and Who can Add comments for that By GNK--> 01 Version 1.0.6
                    getRoadBlock(dispatch, getUser.user)
                    Alert("success", response.message)
                    handleClose();
                    setLoader(false)
                } else if (response.status === 'false' && response.message === "RoadblockExisted") {
                    setLoader(false)
                    Alert("warning", "RoadBlock already existed!");
                }
            } catch (error) {
                console.log(error)
                setLoader(false)
            }
        } else {
            Alert("warning", "please fill all fields");
            setLoader(false)
        }
    }

    const addSubTaskRoadBlock = async (handleClose) => {
        var message = props.data.message + " with Description : " + state.roadblockTitle.value
        if (state.roadblockTitle !== "" && !(state.roadblockTitle.errorStatus)) {
            setLoader(true)
            try {
                var response = await API.post("manage_roadblocks.php", {
                    action: "insert_subtask_roadblock",
                    crop: getUser.user.corp,
                    subTaskId: props.data.subTaskId,
                    mainTaskId: props.data.mainTaskId,
                    roadBlockDescription: state.roadblockTitle.value,
                    empId: getUser.user.empId,
                    priority: props.data.priority,
                    projectId: (props.data.project_id || getUser.user.projectId)
                }, {}, false);
                if (response.status === "True") {
                    addTasksComment(dispatch, getUser.user, props.data.subTaskId, "4", message);
                    Alert("success", response.message)
                    handleClose();
                    setLoader(false)
                } else if (response.status === 'false' && response.message === "RoadblockExisted") {
                    setLoader(false)
                    Alert("warning", "RoadBlock already existed!");
                }
            } catch (error) {
                console.log(error)
                setLoader(false)
            }
        } else {
            Alert("warning", "please fill all fields");
            setLoader(false)
        }
    }
    var userDetails = [];
    state.users.map((users) => {
        return (
            userDetails.push({ 'value': users.id, 'label': users.name })
        );
    })

    useEffect(() => {
        getActiveSprints(backlogDispatch, getUser.user)
        getPending(backlogDispatch, getUser.user)
        // eslint-disable-next-line
    }, [])

    let currentTasks = bState.currentTasks.filter(data => {
        return data.story_type !== 'group' && data.completeStatus === 'pending' && data.userstory_id !== props.data.taskId && data.activeStatus !== "-1" && data.blocked_userid === null
    }).map(item => {
        return { label: `${getSubStringId(getUser.user.corp, 3)}-${item.main_task_id !== null && item.main_task_id !== "0" ? `${item.main_task_id}-` : ''}${item.id}-${item.tasktitle}`, value: item.id, data: item }
    })

    let supportBoardTasks = bState.pendingTasks.filter(data => {
        return data.story_type !== 'group' && data.completeStatus === 'pending' && data.userstory_id !== props.data.taskId && data.activeStatus !== "-1" && data.blocked_userid === null
    }).map(item => {
        return { label: `${getSubStringId(getUser.user.corp, 3)}-${item.main_task_id !== null && item.main_task_id !== "0" ? `${item.main_task_id}-` : ''}${item.id}-${item.tasktitle}`, value: item.id, data: item }
    })

    let combinedTasks = [...currentTasks, ...supportBoardTasks];


    const handleSelectTask = (selectedOption) => {
        setSelectedFromTask(true)
        dispatch(actions.taskSelected(selectedOption));
        dispatch(actions.roadBlockTitle(selectedOption.data.tasktitle))
        dispatch(actions.prioritySelected(parseInt(selectedOption.data.priorityLevel)));
        dispatch(actions.severitySelected(1));
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 col-sm-12 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title">Add RoadBlock </h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body">
                                    <div class="form-group row">
                                        <Select
                                            className="form-control col-12"
                                            placeholder={`Search Existing Task`}
                                            onChange={(selectedOption) => {
                                                handleSelectTask(selectedOption)
                                            }}
                                            value={state.taskSelected}
                                            options={combinedTasks} />
                                    </div>
                                    <div class="form-group row p-2" >
                                        <label for="recipient-name" class="col-form-label">RoadBlock Title<span style={{ color: "red" }} >*</span></label>
                                        <input type="text" class="form-control col-10 m-0" id="recipient-name" name="description" placeholder="Description" value={state.roadblockTitle.value}
                                            onChange={(event) => {
                                                dispatch(actions.roadBlockTitle(event.target.value))
                                            }}
                                            style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }} />
                                        <span class=" col-10 " style={{ color: "red", fontSize: '12px', marginLeft: "90px" }}>{state.roadblockTitle.errorStatus ? state.roadblockTitle.errormessage : ""}</span>
                                    </div>
                                    {!(props.data.action === "subtask_roadblock") && (
                                        <>
                                            <div class="form-group row p-2" style={{ marginTop: '-40px' }}>
                                                <label for="user" className="col-form-label pt-4" style={{ width: '80px' }}>Severity</label>
                                                <Select
                                                    className="form-control col-10 m-1"
                                                    placeholder="Select Severity"
                                                    value={{ label: state.severitySelected.value }}
                                                    onChange={(selectedOption) => {
                                                        dispatch(actions.severitySelected(selectedOption.value));
                                                    }}
                                                    options={severity} />
                                            </div>
                                            <div class="form-group row p-2" style={{ marginTop: '-40px' }}>
                                                <label for="user" className="col-form-label pt-4" style={{ width: '80px' }}>Priority</label>
                                                <Select
                                                    className="form-control col-10 m-1"
                                                    placeholder="Select Priority"
                                                    value={{ label: state.prioritySelected.value }}
                                                    onChange={(selectedOption) => {
                                                        dispatch(actions.prioritySelected(selectedOption.value));
                                                    }}
                                                    options={priority} />
                                            </div>
                                        </>)}
                                </div>
                                <div class="modal-footer">{
                                    selectedFromTask ?
                                        <button disabled={loader} type="button" class="btn btn-outline-success" onClick={() => assignedRoadBlock()} style={{ borderRadius: '20px' }}>Add</button>
                                        :
                                        <button disabled={loader} type="button" class="btn btn-outline-success" onClick={() => (props.data.action === "subtask_roadblock") ? addSubTaskRoadBlock(props.handleClose) : addRoadBlock(props.handleClose)} style={{ borderRadius: '20px' }}>Add</button>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}