import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";


//For Add MainTask
export async function createMainTask(state, dispatch, getUser, handleClose, MAINTASK_ADD, ADD_TO_TODO_PROJECTS, action) {
  if (state.userSelected.from === 'group') {
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      try {
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          action: "addGroup",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: getUser.empId,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          story_type: state.userSelected.from,
          main_task_id: 0,
          group_id: state.userSelected.value
        }, {}, false);
        if (response.status === 'True') {
          getUserIdWithEmails(state.userSelected.members_email, state, dispatch, getUser, handleClose, response.us_id)
          // const message = action === "addUserStory" ? MAINTASK_ADD : ADD_TO_TODO_PROJECTS;
          // Alert("success", message);

        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    }
  } else {
    dispatch(isLoading());
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      try {
        // eslint-disable-next-line
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: state.userSelected.value,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          story_type: state.userSelected.from,
          main_task_id: 0

        }, {}, false);
        if (response.status === 'True') {
          Alert("success", MAINTASK_ADD);
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    }

    else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }

}

export async function createGroupTaskFromTemplate(state, dispatch, getUser, handleClose, storyTitle, MAINTASK_ADD) {
  if (storyTitle !== state.taskTitle.value) {
    if (state.userSelected.from === 'group') {
      if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
        try {
          var response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "add",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            added_to: getUser.empId,
            added_by: getUser.empId,
            acceptanceCriteria: state.acceptanceCriteria.value,
            storyPoints: state.storySelected === "" ? '0' : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
            idea_id: state.epicSelected,
            projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: state.userSelected.from,
            main_task_id: 0,
            group_id: state.userSelected.value
          }, {}, false);
          if (response.status === 'True') {
            Alert("success", MAINTASK_ADD);
            getUserIdWithEmails(state.userSelected.members_email, state, dispatch, getUser, handleClose, response.us_id)
          }
        } catch (error) {
          Alert('error', error.message);
          dispatch(isLoaded());
        }
        handleClose()
      }
    } else {
      dispatch(isLoading());
      if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
        try {
          // eslint-disable-next-line
          var response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "add",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            added_to: state.userSelected.value,
            added_by: getUser.empId,
            acceptanceCriteria: state.acceptanceCriteria.value,
            storyPoints: state.storySelected === "" ? '0' : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
            idea_id: state.epicSelected,
            projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: state.userSelected.from,
            main_task_id: 0

          }, {}, false);
          if (response.status === 'True') {
            Alert("success", MAINTASK_ADD);
          }
        } catch (error) {
          Alert('error', error.message);
          dispatch(isLoaded());
        }
        handleClose()
      }

      else {
        Alert("warning", "Please enter required fields ")
        dispatch(isLoaded());
      }
    }
  } else {
    Alert("warning", "Please change user story title ")
    dispatch(isLoaded());
  }

}

const getUserIdWithEmails = async (members_email, state, dispatch, getUser, handleClose, us_id) => {
  const emails = members_email.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g);
  // Remove duplicates by converting the array to a Set
  const uniqueEmailsSet = new Set(emails);
  // Convert Set back to array if needed
  const uniqueEmailsArray = [...uniqueEmailsSet];
  const filteredEmails = uniqueEmailsArray.filter(email => email !== getUser.userName);
  try {
    var response = await API.post("get_user.php", {
      action: "get_user_by_email",
      emailArray: filteredEmails
    }, {}, false);
    if (response.status === 'True') {
      response.data.map(item => {
        return createGroupMainTask(state, dispatch, getUser, item.empid, parseInt(us_id))
      })
      handleClose()
    }
  } catch (error) {
    console.log(error)
  }
}

export async function createGroupMainTask(state, dispatch, getUser, empId, us_id) {
  dispatch(isLoading());
  if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
    try {

      var response = await API.post("manage_userstories.php",
        {
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: empId,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          story_type: 'group',
          main_task_id: us_id
        }, {}, false);
      if (response.status === 'True') {
        console.log("Success")
      }
    } catch (error) {
      console.log("error")
    }
  }

  else {
    console.log("error")
  }
}