import React, { useEffect, useReducer, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { chatReducer, initialState } from './reducer';
// import { updateChat } from './Services/updateChat';
import { getMessages } from './Services/getMessages';
import { getGroupMembersList } from './Services/getGroupMembersList';
import { getGroupMembersEmail } from './Services/getGroupMembersEmail';
import * as actions from './actions';
import { isLoaded, isLoading } from '../Actions/loading';
import API from '../Network/API';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
// import RootLoader from '../Loader/RootLoad';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import "../vendor/doka.min.css";
import { create } from "../vendor/doka.esm.min";
import convertPSTtoLocalTime from '../convertPSTtoLocalTime';
import { useWebProperties } from '../webProperties';
import { getSubStringId } from '../SubStringConvert';
// import JitsiComponent from '../Jitsi';
import { Resizable } from 're-resizable';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';
import { Meet_URL } from '../config';
import { removeSpecialCharacters } from '../commonUsage';
import jwt from 'jsonwebtoken';
import { Element,scroller } from "react-scroll";
import Highlighter from "react-highlight-words";

// Register the plugins
registerPlugin(FilePondPluginImageEdit, FilePondPluginImagePreview, FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateType, FilePondPluginImageResize, FilePondPluginImageTransform)


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%', // Adjust as needed, or use '100%' for full width
        // maxWidth: 600, // Set a maximum width if needed
        // padding: theme.spacing(3), // Adjust spacing as needed
    },
}));


export default function MainTaskChatBox(props) {

    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(chatReducer, initialState)
    const { CHATAPI, MAINTASK, ATTACH_FILE, role_array } = useWebProperties();
    const [chat, setChat] = useState(0);
    const [copyfile, setCopyFile] = useState({});
    const [docFile, setDocFile] = useState({});
    const [commentDetails, setCommentDetails] = useState({});
    const [handleHover, setHandleHover] = useState(false);
    const [disable, setDisable] = useState(false);
    const [taskMeetingUrl, setTaskMeetingUrl] = useState(null)
    const projectId = props.data.project_id ? props.data.project_id : getUser.user.projectId;
    const project_name = props.data.project_name ? props.data.project_name : getUser.user.corp;
    const [errorMessage, setErrorMessage] = useState(null)
    const [showAllNames, setShowAllNames] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [highlightedMessageId, setHighlightedMessageId] = useState(null);
    const [chatActive, setchatActive]=useState(false);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const handleKeyPress = (e) => {
        if (e.key === "Enter") {
          setShowResults(true);
          setchatActive(true);
        }
      };

      const handleClick = (id) => {
        setHighlightedMessageId(id);
        scroller.scrollTo(`message-${id}`, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      };
    
      const filteredChatData = state.taskComments.filter((item) =>
        item.message.toLowerCase().includes(searchTerm.toLowerCase())
      );
     // console.log("filtered data ::",filteredChatData);

      const clearSearch = () => {
        setSearchTerm('');
        setShowResults(false);
        
      };





    const handleChat = () => {
        setChat(1)
    }
    const handleChatClose = () => {
        setChat(0)
        setHideButton(false)
    }
    useEffect(() => {
        // updateChat(props.data.sno, dispatch, getUser.user)
        updateTaskMeetingUrl(props.data.subtask_id)
        getGroupMembersList(dispatch, getUser.user, props.data);
        getMessages(dispatch, getUser.user, props.data);
        getGroupMembersEmail(dispatch, getUser.user, props.data);
        // eslint-disable-next-line
    }, [])
    const msgId = props.data.sno ? props.data.sno.map((msgId, index) => {
        return msgId.sno
    }) : null

    const modalBodyRef = useRef(null);

    useEffect(() => {
        scrollToBottom();
    }, [state]);

    const scrollToBottom = () => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
        }
    };

    const updateTaskMeetingUrl = async (us_id) => {

        const secretKey = "RALLYWETASKER";

        // Sample payload data
        const payload = {
            "context": {
                "user": {
                    "avatar": "",
                    "name": "",
                    "email": "",
                },
            },
            "aud": "jitsi",
            "iss": "23456789",
            "sub": Meet_URL,
            "room": removeSpecialCharacters(us_id),
            // "exp": 1700060038,
        };
        const generatedToken = jwt.sign(payload, secretKey);
        const meeting_url = `https://${Meet_URL}/${removeSpecialCharacters(us_id)}?jwt=${generatedToken}#jitsi_meet_external_api_id=98765432&config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.autoCaptionOnRecord=true`
        try {
            // eslint-disable-next-line
            const response = await API.post("manage_userstories.php", {
                action: "updateMeetingURLSubtask",
                us_id: us_id,
                meeting_url: meeting_url
            }, {}, false);
            if (response.status === "True") {
                setTaskMeetingUrl(response.meeting_url)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const sendTaskMeetingUrl = () => {
        window.open(taskMeetingUrl, "_blank")
        let message = `${getUser.user.fullName} Started Meeting, to connect <a target="_blank" href='${taskMeetingUrl}'>Click Here</a>`
        // eslint-disable-next-line
        sendMeetUrl(message)
        // eslint-disable-next-line
    }

    const sendMeetUrl = async (message) => {
        let otherEmails = state.groupList.data
            ?.filter((item) => {
                return (
                    item.full_role !== 'Contributor' &&
                    item.full_role !== 'Limited Access Contributor' &&
                    item.full_role !== 'Admin'
                );
            })
            .map((item) => {
                return item.email;
            });

        let taskEmails = [];
        if (state.groupList.data && state.groupList.data.length > 0) {
            const firstDataItem = state.groupList.data[0];
            taskEmails.push(firstDataItem.assigned_to_email || '');
            taskEmails.push(firstDataItem.assigned_by_email || '');
        }

        // Combine the two arrays and remove duplicates
        let allEmails = [...taskEmails, ...otherEmails];
        let uniqueEmails = [...new Set(allEmails)];

        state.taskComments.push({
            "groupId": `${props.data.id}-${props.data.subtask_id}`,
            "message": message,
            "messagedBy": getUser.user.empId,
            "messagedTime": "",
            "reply_message": null,
            "reply_path": null,
            "reply_username": null,
            "path": null,
            "username": getUser.user.fullName
        });

        dispatch(actions.comment(""));

        try {
            // eslint-disable-next-line
            var response = await API.post("user_story_chat.php", {
                action: "send",
                corp_code: getUser.user.corp,
                groupId: `${props.data.id}-${props.data.subtask_id}`,
                message: message,
                messagedBy: getUser.user.empId,
                msgId: msgId ? msgId : " ",
                groupName: `${getSubStringId(state.groupList.project_name, 3)}-${props.data.id}-${props.data.subtask_id}-${state.groupList.story_title}\n\n${state.comment}`,
                groupEmail: uniqueEmails,
                projectId: getUser.user.projectId,
                reply_id: commentDetails.id ? commentDetails.id : "",
            }, {}, false);
            getMessages(dispatch, getUser.user, props.data);
            setDisable(false)
        } catch (error) {
        }
    }

    useEffect(() => {
        if (state.comment.trim() !== "") {
            setErrorMessage(null)
        }
    }, [state.comment])

    const sendMessage = async () => {
        let otherEmails = state.groupList.data
            ?.filter((item) => {
                return (
                    item.full_role !== 'Contributor' &&
                    item.full_role !== 'Limited Access Contributor' &&
                    item.full_role !== 'Admin'
                );
            })
            .map((item) => {
                return item.email;
            });

        let taskEmails = [];
        if (state.groupList.data && state.groupList.data.length > 0) {
            const firstDataItem = state.groupList.data[0];
            taskEmails.push(firstDataItem.assigned_to_email || '');
            taskEmails.push(firstDataItem.assigned_by_email || '');
        }

        // Combine the two arrays and remove duplicates
        let allEmails = [...taskEmails, ...otherEmails];
        let uniqueEmails = [...new Set(allEmails)];


// checking the links in input messages
        let comment =state.comment;
        function isLink(str) {
            // Regular expression to match URLs
            const urlRegex = /(https?:\/\/[^\s]+)/;
            return urlRegex.test(str);
        }

        // Example usage:
        const text = comment;
        let test = comment;
        // Check if the comment contains any new links 
     if (isLink(text)) {
// Find all existing hyperlinks in the comment and wrap them with anchor tags
    test = test.replace(/(<a href="([^"]+)">([^<]+)<\/a>)/g, '$2');

// Replace any new links with anchor tags
test = test.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>");
}
setHandleHover(false);
dispatch(actions.comment(""));
        try {
                // eslint-disable-next-line
                var response = await API.post("user_story_chat.php", {
                    action: "send",
                    corp_code: project_name,
                    groupId: `${props.data.id}-${props.data.subtask_id}`,
                    message: test,
                    messagedBy: getUser.user.empId,
                    msgId: msgId ? msgId : " ",
                    groupName: `${getSubStringId(state.groupList.project_name, 3)}-${props.data.id}-${props.data.subtask_id}-${state.groupList.story_title}\n\n${state.comment}`,
                    groupEmail: uniqueEmails,
                    projectId: projectId,
                    reply_id: commentDetails.id ? commentDetails.id : "",
                }, {}, false);
               // console.log("response", response)

                getMessages(dispatch, getUser.user, props.data);
                setDisable(false)
                setHandleHover(false);
                setCommentDetails({});
                dispatch(isLoaded());
            } catch (error) {
                //console.log("error", error)
                setDisable(false)
            }
            // setDisable(false)
            // dispatch(isLoaded());
            setHandleHover(false);
            dispatch(actions.comment(""));
      // -------------
    //    if (state.comment.trim() !== "") {
    //         setErrorMessage(null)
    //         setDisable(true)
    //         // dispatch(isLoading());
    //         state.taskComments.push({
    //             "groupId": `${props.data.id}-${props.data.subtask_id}`,
    //             "message": state.comment,
    //             "messagedBy": getUser.user.empId,
    //             "messagedTime": "",
    //             "reply_message": null,
    //             "reply_path": null,
    //             "reply_username": null,
    //             "username": getUser.user.fullName
    //         });

    //         let comment = state.comment;
    //         setHandleHover(false);
    //         dispatch(actions.comment(""));

    //         try {
    //             // eslint-disable-next-line
    //             var response = await API.post("user_story_chat.php", {
    //                 action: "send",
    //                 corp_code: project_name,
    //                 groupId: `${props.data.id}-${props.data.subtask_id}`,
    //                 message: comment,
    //                 messagedBy: getUser.user.empId,
    //                 msgId: msgId ? msgId : " ",
    //                 groupName: `${getSubStringId(state.groupList.project_name, 3)}-${props.data.id}-${props.data.subtask_id}-${state.groupList.story_title}\n\n${state.comment}`,
    //                 groupEmail: uniqueEmails,
    //                 projectId: projectId,
    //                 reply_id: commentDetails.id ? commentDetails.id : "",
    //             }, {}, false);
    //            // console.log("response", response)

    //             getMessages(dispatch, getUser.user, props.data);
    //             setDisable(false)
    //             setHandleHover(false);
    //             setCommentDetails({});
    //             dispatch(isLoaded());
    //         } catch (error) {
    //             console.log("error", error)
    //             setDisable(false)
    //         }
    //         setDisable(false)
    //         dispatch(isLoaded());
    //     } else {
    //         setDisable(false)
    //         setHideButton(false)
    //         setErrorMessage("Please type the message")
    //     } 
    // -------------
    }

    // on enter message will be send
    // const handleKeypress = e => {
    //     //it triggers by pressing the enter key
    //     if (e.which === 13) {
    //         if (state.comment.trim() !== "") {
    //             //   console.log("in if")
    //             sendMessage();
    //         } else {
    //             //   console.log("in else")
    //             fileHandle(docFile)
    //         }
    //     }
    // };



    // for sending files or images
    // eslint-disable-next-line
    const fileHandle = async (file) => {
        let otherEmails = state.groupList.data
            ?.filter((item) => {
                return (
                    item.full_role !== 'Contributor' &&
                    item.full_role !== 'Limited Access Contributor' &&
                    item.full_role !== 'Admin'
                );
            })
            .map((item) => {
                return item.email;
            });

        let taskEmails = [];
        if (state.groupList.data && state.groupList.data.length > 0) {
            const firstDataItem = state.groupList.data[0];
            taskEmails.push(firstDataItem.assigned_to_email || '');
            taskEmails.push(firstDataItem.assigned_by_email || '');
        }

        // Combine the two arrays and remove duplicates
        let allEmails = [...taskEmails, ...otherEmails];
        let uniqueEmails = [...new Set(allEmails)];

        // state.taskComments.push({
        //     "groupId": props.data.id,
        //     "message": state.comment,
        //     "messagedBy": getUser.user.empId,
        //     "messagedTime": "",
        //     "path": URL.createObjectURL(docFile) || URL.createObjectURL(copyfile),
        //     "reply_message": null,
        //     "reply_path": null,
        //     "reply_username": null,
        //     "username": getUser.user.fullName
        // });

        // let comment = state.comment;
        // dispatch(actions.comment(""));

        if (file !== "" && file.name !== undefined) {
            setDisable(true)
            dispatch(isLoading());
            const data = new FormData();
            data.append('file', file);
            data.append('message', file.name);
            // required data for send message
            data.append('action', 'media');
            data.append('corp_code', project_name);
            data.append('groupId', `${props.data.id}-${props.data.subtask_id}`);
            data.append('messagedBy', getUser.user.empId);
            data.append('api', CHATAPI);
            data.append('groupName', `${getSubStringId(state.groupList.project_name, 3)}-${props.data.id}-${props.data.subtask_id}-${state.groupList.story_title}\n\n${"Attached New File"}`);
            data.append('groupEmail', uniqueEmails);
            data.append('reply_id', commentDetails.id ? commentDetails.id : "");
            try {
                var response = await API.post("user_story_chat1.php", data
                    , {}, false);
                console.warn(response)
                getMessages(dispatch, getUser.user, props.data);
                dispatch(actions.comment(""));
                setChat(0)
                setDocFile({})
                setDisable(false)
                setHandleHover(false);
                setCommentDetails({});
                setHideButton(false)
            } catch (error) {
                console.log(error)
                setDisable(false)
                setHideButton(false)
            }
            setDisable(false)
            dispatch(isLoaded());
        } else {
            setDisable(false)
            setErrorMessage("Please type the message")
        }
    }
    // console.log(state.comment)
    // for getting pasted image's value
    const myPaste = (e) => {
        window.addEventListener('paste', e => {
            setCopyFile(e.clipboardData.files[0])
            if (e.clipboardData.files.length !== 0) {
                setChat(2)
            }
        });

    }
    const handleDoc = (file) => {
        const fileData = file.file
        // console.log("file",fileData)
        setDocFile(fileData)
    }

    var textInput = useRef(null);
    // for handling reply 
    const handlecom = (comment) => {
        setCommentDetails(comment)
        setHandleHover(true);
        if (textInput) textInput.current.focus();
    }

    const handleMouseOut = () => {
        setHandleHover(false);
    };

    const [size, setSize] = useState({ width: isMobile ? '90%' : 700, height: 500 });

    const handleResizeStop = (e, direction, ref, d) => {
        setSize((prevSize) => ({
            width: prevSize.width + d.width,
            height: prevSize.height + d.height,
        }));
    };

    const handleDrag = (e, data) => {
        // Handle drag event if needed
       // console.log('Dragging...', data.x, data.y);
    };

    const getInitialPosition = () => {

        const initialX = 438;
        const initialY = 77.5;
        return { x: initialX, y: initialY };
    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.which === 13) {
            if (state.comment.trim() !== "") {
                sendMessage();
            } else {
                fileHandle(docFile)
            }
        }
    };

    const [hideButtons, setHideButton] = useState(false)

    const handleToggleNames = () => {
        setShowAllNames(!showAllNames);
    };

    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                // className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <Fade in={props.open}>
                    <Draggable handle="#draggable-modal" onDrag={handleDrag} defaultPosition={getInitialPosition()}>
                        <Resizable
                            size={size}
                            onResizeStop={handleResizeStop}
                            minWidth={isMobile ? "" : 700}
                            minHeight={500}
                        >
                            <div
                                className={classNames.paper}

                            >
                                <div className="user-modal-dialog">

                                    <div className="modal-content p-2 tsk" style={{ borderRadius: '10px', height: size.height }}>
                                        {

                                            <>
                                                <div id="draggable-modal" class="modal-header" style={{ backgroundColor: '#eaeaea', cursor: 'move' }}>
                                                    <div className="column col-sm-12" >
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                                     
                                                        <h6 class="modal-title mb-2">{MAINTASK}: {getSubStringId(props.data.project_name || state.groupList.project_name, 3)}-{" "}

                                                            {`${props.data.id}-${props.data.subtask_id}`}{" "}{state.groupName}
                                                            {/* <a target="_blank" rel="noopener noreferrer" href={taskMeetingUrl} title="Join rally meeting click here">
                                                                <img style={{width: '24px'}} className='chat-send-icons' src="images/common/video_call.svg" alt="Meet" />
                                                            </a> */}
                                                        </h6>
                                                         {/* <button style={{ border: 'none', background: 'transparent' }} onClick={handleToggleNames} onTouchStart={handleToggleNames} className='dropdown-toggle'></button> */}
                                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} onTouchEnd={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>

                                                        </div>
                                                        {/* <p class="modal-title" title={state.groupList}>{state.groupList}</p> */}
                                                        <div>
                                                            <div className='flex'>

                                                          
                                                             <label className='badge badge-pill badge-light text-color-green' title={`${state.groupList.assigned_to}-Assigned To`}>{state.groupList.assigned_to}-AT</label>{" "}
                                                            <label className='badge badge-pill badge-light text-color-green' title={`${state.groupList.assigned_by}-Assigned By`}>{state.groupList.assigned_by}-AB</label>{" "}
                                                        
                                                            <button style={{ border: 'none', background: 'transparent' }} onClick={handleToggleNames} onTouchStart={handleToggleNames} className='dropdown-toggle'></button>
                                                    {/* <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} onTouchEnd={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button> */}

                                                            <div class="" style={{position:"relative" ,float:'right', display: 'flex', justifyContent: 'flex-end',marginBottom:'2.5rem' ,marginRight:'1rem'}}>
                                                                           {/* <SearchIcon fontSize={"medium"} backgroundColor={"green"}/> */}
                                    <input type="text" class="form-control"  style={{borderRadius:'12px',padding: '6px 12px', }} 
                                    placeholder=" Search here" aria-label="Recipient's username" aria-describedby="button-addon2"
                                    value={searchTerm}
                                     onChange={handleSearchChange}
                                    onKeyPress={handleKeyPress}   
                                    // value={state.aitaskinput.value}
                                       // onChange={(event)=>dispatch(actions.aitaskinput(event.target.value))}
                                        />
                                        {searchTerm && (
        <button
          onClick={clearSearch}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: '0',
            outline: 'none',
            fontSize: '16px'
          }}
        >
          &#x2715; {/* This is the 'X' character */}
        </button>
      )}

                                        </div>
                                        </div>
                                                            {showAllNames && (
                                                                <div className="user-modal-dialog" style={{ minHeight: '100px', maxHeight: '300px', overflow: 'auto' }}>
                                                                    {state.groupList.data?.filter(item => item.full_role !== 'Admin').map(item => (
                                                                        <>
                                                                            <label className='badge badge-pill badge-light text-color-green mr-1 mtc' title={`${item.display_name !== null ? item.display_name : item.full_name}-${role_array[item.full_role]}`}>
                                                                                {`${item.display_name !== null ? item.display_name : item.full_name}-${role_array[item.full_role]}`}
                                                                            </label>

                                                                        </>
                                                                    ))
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} onTouchEnd={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                                </div>
                                                <div className="modal-body" style={{ overflow: 'auto' }} ref={modalBodyRef}>
                                                    <div className="overflow-auto" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                                                        
                                                    {chatActive && showResults && filteredChatData.length > 0
          ? filteredChatData.map((item,index) => (
            <>
              <Element
                name={`message-${item.id}`}
                key={item.id}
                onClick={() => handleClick(item.id)}
                style={{
                
                  marginBottom: "5px",
                  backgroundColor:
                    highlightedMessageId === item.id ? "#e1e1e1" : "#fff",
                  border:
                    highlightedMessageId === item.id,
                   
           
                  cursor: "pointer",
                }}
              > 
              <q style={{ color: 'grey', fontSize: "8px" }}>{convertPSTtoLocalTime(item.messagedTime)}</q>
             
                <div className='flex ' style={{display:'flex'}}> 
                <div style={{ fontWeight: "bold",color:'green'}}>
                  {item.username} : 
                </div>  
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={[searchTerm]}
                  autoEscape={true}
                //   style={{color:"yellow"}}
                  textToHighlight={item.message}
                />
                </div>
                 {/* </div> */}
            
              </Element>
            {index < filteredChatData.length - 1 && <hr />}
              </>


            ))
          : showResults && <div>No results found</div>}
                                                        
                                                        
                                                        {!showResults && state.taskComments !== '' ? state.taskComments.slice(0).reverse().map((comments, index) => {

                                                            return (
                                                                <div key={index}>
                                                                    {
                                                                        comments.messagedBy !== getUser.user.empId ?


                                                                            <p className="text-left pr-3"  >
                                                                                <p className="pt-2" style={{ color: 'green' }}>{comments.username}&nbsp;<q style={{ color: 'grey', fontSize: "8px" }}>{convertPSTtoLocalTime(comments.messagedTime)}</q></p>
                                                                                {comments.path ?
                                                                                    <div style={{ height: "67%", backgroundColor: "#f3f3f3", float: "left", borderRadius: '7px' }}>

                                                                                        {/* for image with reply content and dropdown left side content */}
                                                                                        {comments.reply_message !== null ?
                                                                                            <li className="dropdown show" style={{ listStyle: "none", float: "right" }}>
                                                                                                <div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", padding: "10px" }}>
                                                                                                    <p style={{ color: "green", }}>{comments.reply_username}</p>
                                                                                                    <p style={{ display: "inline-block", textAlign: 'left', width: "100%", whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "5px" }}>
                                                                                                        {comments.reply_path ?
                                                                                                            comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                                // eslint-disable-next-line 
                                                                                                                <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                    <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                                !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                                    //  eslint-disable-next-line  
                                                                                                                    <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                        {comments.reply_message}</a> :
                                                                                                                    //  eslint-disable-next-line  
                                                                                                                    <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                        <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                                            :
                                                                                                            <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>
                                                                                                        }
                                                                                                    </p></div>
                                                                                                {/* drop-down icon and reply button */}
                                                                                                <p style={{ borderRadius: '30px', color: "gray", padding: '0px', cursor: 'pointer', float: 'right' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></p>
                                                                                                <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                    {/* eslint-disable-next-line   */}
                                                                                                    <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                                </div>
                                                                                                {/* End of drop-down icon and reply button */}

                                                                                                <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'right', padding: "12px 10px 12px 14px", borderRadius: "7px" }}>
                                                                                                    {/* {comments.message} */}
                                                                                                    {comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                        //  eslint-disable-next-line
                                                                                                        <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                            <img style={{ marginTop: '-12px', marginRight: '12px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                        :
                                                                                                        !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                            //  eslint-disable-next-line
                                                                                                            <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                                            :
                                                                                                            //  eslint-disable-next-line 
                                                                                                            <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                                <img style={{ marginTop: '-14px', marginRight: '14px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                    }
                                                                                                    {/* eslint-disable-next-line  */}

                                                                                                </p>
                                                                                            </li>

                                                                                            :
                                                                                            // for image with left side dropdown and image only
                                                                                            <li className="dropdown show" style={{ listStyle: "none" }}>
                                                                                                <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '0px', float: 'right' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                </a>
                                                                                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                    {/* eslint-disable-next-line */}
                                                                                                    <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                                </div>
                                                                                                <p style={{ backgroundColor: "#f3f3f3", padding: "12px 10px 12px 14px", borderRadius: "7px", marginTop: '4px' }}>

                                                                                                    {comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                        //   eslint-disable-next-line 
                                                                                                        <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                            <img style={{ marginTop: '-8px', marginRight: '12px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                        :
                                                                                                        !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                            //    eslint-disable-next-line 
                                                                                                            <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                                            :
                                                                                                            //  eslint-disable-next-line 
                                                                                                            <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                                <img style={{ marginTop: '-8px', marginRight: '12px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                    }
                                                                                                    {/* eslint-disable-next-line */}

                                                                                                </p>
                                                                                            </li>
                                                                                        }</div>
                                                                                    :
                                                                                    //for message display reply content and dropdown left side reply messages 
                                                                                    comments.reply_message !== null ?
                                                                                        <li className="dropdown show" style={{ listStyle: "none", float: "left" }}>
                                                                                            <div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", width: '100%', textAlign: 'left', padding: "12px 10px 12px 14px" }}>
                                                                                                <p style={{ color: "green", }}>{comments.reply_username}</p>
                                                                                                <p style={{ display: "inline-block", whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "12px" }}>
                                                                                                    {comments.reply_path ?
                                                                                                        comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                            // eslint-disable-next-line 
                                                                                                            <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                            !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                                //  eslint-disable-next-line  
                                                                                                                <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                    {comments.reply_message}</a> :
                                                                                                                //  eslint-disable-next-line  
                                                                                                                <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                    <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                                        :
                                                                                                        <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                            <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '10px', float: 'right', marginTop: '-8px' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            </a>
                                                                                            <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                {/* eslint-disable-next-line   */}
                                                                                                <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                            </div>
                                                                                            <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius: "7px", marginTop: '-8px' }}>
                                                                                                <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.message }}></div>
                                                                                                {/* eslint-disable-next-line  */}
                                                                                            </p>
                                                                                        </li>
                                                                                        :
                                                                                        // for message display dropdown left side of chat box messages
                                                                                        <li className="dropdown show" style={{ listStyle: "none" }}>
                                                                                            <div className="" style={{
                                                                                                margin: '0px', display: 'flex',
                                                                                                alignItems: 'normal',
                                                                                                justifyContent: 'start'
                                                                                            }}>
                                                                                                <div className="" style={{ maxWidth: 'fit-content', padding: '0px' }}>
                                                                                                    <p style={{ backgroundColor: "#f3f3f3", width: 'fit-content', textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius: "7px 0px 0px 7px", paddingTop: '12px', paddingLeft: '14px' }}>
                                                                                                        <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.message }}></div>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="" style={{ backgroundColor: "#f3f3f3", marginBottom: '4.5px', padding: '0px', borderRadius: "0px 7px 7px 0px" }}>
                                                                                                    <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '0px', marginTop: '12px' }} className="btn dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                    </a>
                                                                                                    <div className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                        {/* eslint-disable-next-line   */}
                                                                                                        <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <p style={{backgroundColor:"#f3f3f3", width:'fit-content',textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius:"7px" , paddingTop:'12px',paddingLeft:'14px',wordBreak:'break-all'}}>
                {comments.message} </p> */}
                                                                                            {/* eslint-disable-next-line  */}


                                                                                        </li>}</p> :


                                                                            //user messages     user message    user  messages
                                                                            <p className="text-right pr-3"  ><p className="pt-2" style={{ color: 'green' }}>{comments.username}&nbsp;<q style={{ color: 'grey', fontSize: "8px" }}>{convertPSTtoLocalTime(comments.messagedTime)}</q></p>
                                                                                {comments.path ?
                                                                                    <div style={{ height: "67%", backgroundColor: "#f3f3f3", padding: '0px', float: "right" }}>

                                                                                        {/* for image with reply content and dropdown for right side chat    */}
                                                                                        {comments.reply_message !== null ?
                                                                                            <li className="dropdown show" style={{ listStyle: "none", float: "right" }}>
                                                                                                <div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", padding: "10px" }}>
                                                                                                    <p style={{ color: "green", }}>{comments.reply_username}</p>
                                                                                                    <p style={{ display: "inline-block", textAlign: 'right', width: "100%", whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "5px" }}>
                                                                                                        {comments.reply_path ?
                                                                                                            comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                                // eslint-disable-next-line 
                                                                                                                <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                    <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                                !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                                    //  eslint-disable-next-line  
                                                                                                                    <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                        {comments.reply_message}</a> :
                                                                                                                    //  eslint-disable-next-line  
                                                                                                                    <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                        <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                                            :
                                                                                                            <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>

                                                                                                        }
                                                                                                    </p></div>
                                                                                                <p style={{ borderRadius: '30px', color: "gray", padding: '0px', cursor: 'pointer' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></p>
                                                                                                <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                    {/* eslint-disable-next-line   */}
                                                                                                    <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                                </div>
                                                                                                <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'right', padding: "12px 10px 12px 14px", borderRadius: "7px" }}>
                                                                                                    {/* {comments.message} */}
                                                                                                    {comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                        //  eslint-disable-next-line
                                                                                                        <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                            <img style={{ marginTop: '-20px', marginRight: '15px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                        :
                                                                                                        !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                            //  eslint-disable-next-line
                                                                                                            <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                                            :
                                                                                                            //  eslint-disable-next-line 
                                                                                                            <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                                <img style={{ marginTop: '-20px', marginRight: '15px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                    }
                                                                                                    {/* eslint-disable-next-line  */}
                                                                                                </p>
                                                                                            </li>
                                                                                            :
                                                                                            // for image with right side dropdown and image only
                                                                                            <li className="dropdown show" style={{ listStyle: "none" }}>
                                                                                                {/* drop-down icon and reply button */}
                                                                                                <p style={{ borderRadius: '30px', color: "gray", padding: '0px' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                </p>
                                                                                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                    {/* eslint-disable-next-line */}
                                                                                                    <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                                </div>
                                                                                                {/*End of drop-down icon and reply button */}
                                                                                                <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius: "7px" }}>
                                                                                                    {comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                        //  eslint-disable-next-line
                                                                                                        <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                            <img style={{ marginTop: '-25px', marginRight: '15px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                        :
                                                                                                        !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                            //  eslint-disable-next-line
                                                                                                            <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                                            :
                                                                                                            //  eslint-disable-next-line 
                                                                                                            <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                                <img alt={comments.message} src={comments.path} width="400" /></a>
                                                                                                    }
                                                                                                    {/* eslint-disable-next-line */}

                                                                                                </p>
                                                                                            </li>
                                                                                        }</div>
                                                                                    //for message display reply content and dropdown right side content
                                                                                    : comments.reply_message !== null ?
                                                                                        <li className="dropdown show" style={{ listStyle: "none", float: "right" }}>
                                                                                            <div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", width: '100%', textAlign: 'left', padding: '12px 10px 12px 14px' }}>
                                                                                                <p style={{ color: "green", float: "left" }}>{comments.reply_username}</p><br />
                                                                                                <p
                                                                                                    style={{ display: "inline-block", width: '100%', textAlign: 'left', whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "5px" }}>
                                                                                                    {comments.reply_path ?
                                                                                                        comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                            // eslint-disable-next-line 
                                                                                                            <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                            !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                                //  eslint-disable-next-line  
                                                                                                                <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                    {comments.reply_message}</a> :
                                                                                                                //  eslint-disable-next-line  
                                                                                                                <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                                    <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                                        :
                                                                                                        <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                            <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '10px', float: 'right', marginTop: '-8px' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            </a>
                                                                                            <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                {/* eslint-disable-next-line   */}
                                                                                                <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                            </div>

                                                                                            <p style={{ backgroundColor: "#f3f3f3", width: '100%', padding: "12px 10px 12px 14px", borderRadius: "7px", marginTop: '-8px', textAlign: 'left' }}>
                                                                                                <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.message }}></div>
                                                                                                {/* eslint-disable-next-line  */}
                                                                                            </p>
                                                                                        </li>
                                                                                        :
                                                                                        //for message display dropdown right side chat box messages
                                                                                        <li className="dropdown show" style={{ listStyle: "none", width: '100%' }}>
                                                                                            <div className="" style={{
                                                                                                margin: '0px', display: 'flex',
                                                                                                alignItems: 'normal',
                                                                                                justifyContent: 'end'
                                                                                            }}>
                                                                                                <div className="" style={{ padding: '0px' }}>
                                                                                                    <p style={{ backgroundColor: "#f3f3f3", padding: "12px 10px 12px 14px", textAlign: 'left', borderRadius: "7px 0px 0px 7px", float: 'right' }}>
                                                                                                        <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.message }}></div>
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div className="" style={{ backgroundColor: "#f3f3f3", marginBottom: '4.5px', padding: '0px', textAlign: 'left', borderRadius: "0px 7px 7px 0px" }}>
                                                                                                    {/* eslint-disable-next-line  */}
                                                                                                    <a style={{ borderRadius: '30px', color: "gray", padding: '0px', marginTop: '12px' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                                                                                    <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                                        {/* eslint-disable-next-line   */}
                                                                                                        <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>


                                                                                        </li>}</p>
                                                                    }
                                                                </div>
                                                            )
                                                        }) : null}
                                                    </div>
                                                </div>
                                                {/* {
                                                 state.isLoading &&
                                                 <RootLoader />
                                                 
                                            } */}
                                                <div class="modal-footer m-1 justify-content-center">
                                                    <div className="form-sample col-12">
                                                        <form encType="multipart/form-data" method="post" autoComplete="off">
                                                            {/* <div className="form-sample col-12"> */}
                                                            {/* <label>Resume*</label> */}
                                                            {chat === 1 ?
                                                                <div><button style={{ backgroundColor: 'transparent', border: '0', float: "right" }} type="button" onClick={() => handleChatClose()} className="d-flex align-items-right" ><i class="mdi mdi-close text-black"></i></button>
                                                                    <FilePond
                                                                        allowFileTypeValidation={true}
                                                                        fileValidateTypeLabelExpectedTypes={['allTypes']}
                                                                        allowMultiple={true}
                                                                        // maxFiles={3}
                                                                        maxFiles={1}
                                                                        instantUpload={false}
                                                                        storeAsFile={true}
                                                                        // eslint-disable-next-line
                                                                        credits={"", ""}
                                                                        onaddfilestart={(file) => {
                                                                            handleDoc(file)
                                                                            setHideButton(true)
                                                                        }}// for show uplode icon
                                                                        // allowProcess = {false}
                                                                        // start for image editor
                                                                        allowReorder={true}
                                                                        // imageEditInstantEdit = {true}
                                                                        onpreparefile={(file, output) => {
                                                                            // console.log("prepare file", file.getMetadata());
                                                                            // console.log("prepare", output);
                                                                            const img = document.createElement("img");
                                                                            img.src = URL.createObjectURL(output);
                                                                            // document.body.appendChild(img);
                                                                        }}
                                                                        imageResizeUpscale={false}
                                                                        imageResizeMode={"contain"}
                                                                        imageEditIconEdit='<svg width="26" height="26" viewBox="0 0 26 26" 
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z"
                                                                   fill="blue" fill-rule="nonzero" stroke="blue" stroke-width="2"></path></svg>'
                                                                        imageEditEditor={create({
                                                                            onconfirm: () => {
                                                                                setHideButton(true)
                                                                            },
                                                                            cropMinImageWidth: 128,
                                                                            cropMinImageHeight: 128
                                                                        })}
                                                                        // end for image editor
                                                                        server={{
                                                                            // fake server to simulate loading a 'local' server file and processing a file
                                                                            process: (fieldName, file, metadata, load) => {
                                                                                // simulates uploading a file
                                                                                setTimeout(() => {
                                                                                    load(Date.now())
                                                                                    fileHandle(file)
                                                                                    // handleImageChange(file)
                                                                                }, 1000);
                                                                            },
                                                                            load: (source, load) => {
                                                                                // simulates loading a file from the server
                                                                                fetch(source).then(res => res.blob()).then(load);
                                                                            }
                                                                        }}
                                                                    >

                                                                    </FilePond>
                                                                </div>
                                                                : chat === 2 ?
                                                                    <div>
                                                                        <button style={{ backgroundColor: 'transparent', border: '0', float: "right" }} type="button" onClick={() => handleChatClose()} className="d-flex align-items-right" ><i class="mdi mdi-close text-black"></i></button>

                                                                        <FilePond
                                                                            allowFileTypeValidation={true}
                                                                            fileValidateTypeLabelExpectedTypes={['allTypes']}
                                                                            allowMultiple={true}
                                                                            maxFiles={1}
                                                                            instantUpload={false}
                                                                            files={[copyfile]}
                                                                            storeAsFile={true}
                                                                            // eslint-disable-next-line
                                                                            credits={"", ""}
                                                                            // allowProcess = {false}
                                                                            onaddfilestart={(file) => {
                                                                                handleDoc(file)
                                                                                setHideButton(true)
                                                                            }}
                                                                            // start for image editor
                                                                            allowReorder={true}
                                                                            onpreparefile={(file, output) => {
                                                                                // console.log("prepare file", file.getMetadata());
                                                                                // console.log("prepare", output);
                                                                                const img = document.createElement("img");
                                                                                img.src = URL.createObjectURL(output);
                                                                                // document.body.appendChild(img);
                                                                            }}
                                                                            imageResizeUpscale={false}
                                                                            imageResizeMode={"contain"}
                                                                            imageEditIconEdit='<svg width="26" height="26" viewBox="0 0 26 26" 
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z"
                                                                   fill="blue" fill-rule="nonzero" stroke="blue" stroke-width="2"></path></svg>'
                                                                            imageEditEditor={create({
                                                                                onconfirm: () => {
                                                                                    setHideButton(true)
                                                                                },
                                                                                cropMinImageWidth: 128,
                                                                                cropMinImageHeight: 128
                                                                            })}
                                                                            // end for image editor
                                                                            server={{
                                                                                // fake server to simulate loading a 'local' server file and processing a file
                                                                                process: (fieldName, files, metadata, load) => {
                                                                                    // simulates uploading a file
                                                                                    setTimeout(() => {
                                                                                        load(Date.now())
                                                                                        fileHandle(files)
                                                                                        // handleImageChange(file) 
                                                                                    }, 1000);
                                                                                },
                                                                                load: (files, load) => {
                                                                                    // simulates loading a file from the server
                                                                                    fetch(files).then(res => res.blob()).then(load);
                                                                                }
                                                                            }}
                                                                        >
                                                                        </FilePond>
                                                                    </div>
                                                                    : ""}
                                                        </form>
                                                        {handleHover &&
                                                            <div className=" col-md-9" style={{ backgroundColor: "#eee", borderRadius: '7px', marginBottom: "3px", paddingBottom: "2px" }}>
                                                                <button style={{ backgroundColor: 'transparent', border: '0', marginLeft: "89%" }} type="button" onClick={handleMouseOut} className="d-flex align-items-right" data-dismiss="modal"><i className="mdi mdi-close text-black"></i></button>
                                                                <p style={{ color: "green", marginLeft: "16px", marginTop: "-29px" }}>{commentDetails.username}</p>
                                                                <p style={{ marginLeft: "16px" }}>
                                                                    {commentDetails.path ?
                                                                        commentDetails.path.substr(commentDetails.path.length - 4) === "blob" && commentDetails.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                            // eslint-disable-next-line 
                                                                            <a download={commentDetails.path} className='text-primary' target="_blank" href={commentDetails.path}>
                                                                                <img alt={commentDetails.message} src={commentDetails.path} width="60" height="60" /></a> :
                                                                            !commentDetails.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                //  eslint-disable-next-line  
                                                                                <a download={commentDetails.message} className='text-primary' target="_blank" href={commentDetails.path}>
                                                                                    {commentDetails.message}</a> :
                                                                                //  eslint-disable-next-line  
                                                                                <a download={commentDetails.path} className='text-primary' target="_blank" href={commentDetails.path}>
                                                                                    <img alt={commentDetails.message} src={commentDetails.path} width="60" height="60" />{commentDetails.message}</a>
                                                                        :
                                                                        <div style={{ whiteSpace: 'break-spaces', overflow: 'auto', maxHeight: '70px' }} dangerouslySetInnerHTML={{ __html: commentDetails.message }}></div>
                                                                    }
                                                                    {/* {commentDetails.message} */}
                                                                </p>
                                                            </div>
                                                        }
                                                        {/* </div> */}
                                                        {
                                                            !hideButtons ?
                                                                <>
                                                                    <div className="row" style={{ display: 'flex', width: '100%' }}>
                                                                        <div style={{ flex: '1' }}>
                                                                            <textarea
                                                                                autoFocus
                                                                                className="form-control copyable-text selectable-text"
                                                                                ref={textInput}
                                                                                id="recipient-name document_attachment_doc"
                                                                                style={{
                                                                                    maxHeight: '200px',
                                                                                    overflowY: 'scroll',
                                                                                    borderColor: 'grey',
                                                                                    borderRadius: '20px',
                                                                                    height: '2.2rem',
                                                                                    padding: '7px',
                                                                                    width: '100%', // Equal width for textarea
                                                                                }}
                                                                                contentEditable="true"
                                                                                suppressContentEditableWarning={true}
                                                                                placeholder="Type a message"
                                                                                onKeyPress={handleKeypress}
                                                                                value={state.comment}
                                                                                onChange={(event) => dispatch(actions.comment(event.target.value))}
                                                                                onPaste={(e) => myPaste(e)}
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginRight: '1%', display: 'flex', alignItems: 'center' }}>
                                                                            <div title={ATTACH_FILE} onClick={handleChat}>
                                                                                <img className='chat-send-icons' src="images/common/attachment.svg" alt="Attach File" />
                                                                            </div>
                                                                            <div>
                                                                                <img className='chat-send-icons' src="images/common/video_call.svg" alt="Meet" onClick={() => sendTaskMeetingUrl()} />
                                                                            </div>
                                                                            <div>
                                                                                <button disabled={state.comment.trim() === ""} type="button" style={{ background: 'transparent', border: 'none', padding: '0px' }}>

                                                                                    <img
                                                                                        className='chat-send-icons'
                                                                                        src={state.comment.trim() === "" ? "images/common/send_disable.svg" : "images/common/send.svg"}
                                                                                        alt="Send"
                                                                                        onClick={() => (disable ? undefined : (chat === 1 || chat === 2 ? fileHandle(docFile) : sendMessage()))}
                                                                                    />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        errorMessage !== null ?
                                                                            <label className='text-danger'>{errorMessage}</label>
                                                                            :
                                                                            null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>

                                                {/* <div class="modal-footer m-1">
                                
                                    <input type="text" class="form-control col-10 " id="recipient-name" style={{ borderColor: 'grey', borderRadius: '20px' }} 
                                     value={state.comment} onChange={(event) => dispatch(actions.comment(event.target.value))} onKeyPress={handleKeypress}/>
                                    <button type="submit" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => sendMessage()}>Send</button>
                                  
                                </div> */}
                                            </>
                                        }
                                        {/* {
                                            meetOpen &&
                                            <JitsiComponent open={meetOpen} roomName={props.data.id} handleClose={(data) => setMeetOpen(false)} updateMeetingUrl={(url) => setMeetingUrl(url)} handleMeetingEnd={() => endMeeting()} />
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </Resizable>
                    </Draggable>
                </Fade>
            </Modal>
        </div >
    );
}