import React, { useEffect, useState, useReducer } from "react";
import Board from "react-trello";
import TopNav from "../Utility/TopNav";
import SideBar from "../Utility/SideNav";
import API from "../../Common/Network/API";
import { setCurrentUser } from '../../Common/Actions';
import { useSelector } from "react-redux";
import ChatBox from "../../Common/ChatMainTask";
import Alert from "../../Common/Alert";
import RoadBlock from "../../Common/RoadBlock";
import RootLoader from "../../Common/Loader/RootLoader";
import RoadBlockModal from "../UserDashboard/roadblockModal";
import TaskInfo from "../UserDashboard/taskInfo";
import UpdateTask from "../UserDashboard/updateTask";
import { moduleReducer, initialState } from "./moduleReducer";

import Moment from "moment";
import Select from "react-select";
import {
  sprintSelected,
  activeSprint,
  activeUserStory,
  roadblockFilter,
  todoFilter,
  doingFilter,
  doneFilter,
  todo,
  doing,
  done,
  roadblock,
} from "./actions";
import store from "../../Common/Store";
import AddModule from "../../Common/Modules/addModule";
import { getSubStringId, getSubStringSprintId } from "../../Common/SubStringConvert";
import ChangeUserstoryStatus from "./changeUserstoryStatus";
import convertPSTtoLocalTime from "../../Common/convertPSTtoLocalTime";
import { useWebProperties } from "../../Common/webProperties";
import { filteredData, getCompleteData, getCompleteRoadblockData } from "../../Common/commonUsage";
import { getActiveUserStory } from "./Services/getActiveUserStory";
import { getCurrentSprintUserStories } from "./Services/getCurrentSprintUserStories";
import { getToDoUserStories } from "./Services/getToDoUserStories";
import { getDoneUserStories } from "./Services/getDoneUserStories";
import { getInvolvedEmployees } from "./Services/getInvolvedEmployees";
import { getModules } from "./Services/getModules";
import { getActiveSprints } from "./Services/getActiveSprints";
import { updateUserStory } from "./Services/updateUserStory";
import { updateUserstotyStaus } from "./Services/updateUserstotyStaus";
import { getAllUserStoryMessages } from "./Services/getAllUserStoryMessages";
// import { getWorkingDays } from "./Services/getWorkingDays";
// import { getWorkingHours } from "./Services/getWorkingHours";
import { getRoadBlockUserStories } from "./Services/getRoadblockUserStories";
import SubTasksInfo from "./subtasksInfo";

const CustomCard = ({
  onClick,
  className,
  laneId,
  title,
  id,
  us_id,
  story_desc,
  subTaskDesc,
  status,
  story_status,
  mainTaskid,
  activeStatus,
  dependencyId,
  timeLeft,
  extraHours,
  mainTaskTitle,
  taskDesc,
  ideaTitle,
  epic_name,
  moduleDesc,
  story_points,
  priority_level,
  acceptanceCriteria,
  assignedDate,
  modifiedDate,
  targetDate,
  complete_status,
  modifiedby,
  assignedto,
  blockedId,
  userStoryId,
  cardStyle,
  body,
  dueOn,
  cardColor,
  description,
  label,
  escalationText,
  tags,
  showDeleteButton,
  onDelete,
  blocked_id,
  story_id,
  task_end_date,
  assignedby,
  target_date,
  main_task_id,
  story_type,
  task_count,
  road_block_ids,
  combine_tasks,
  blocking_id
}) => {
  // const [name] = assignedto.split("@");
  const [chatOpen, setChatOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState({ status: false });
  const [cardInfo, setCardInfo] = useState();
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(moduleReducer, initialState);
  const { COMMENTS, CHAT, PRIORITY_LEVEL, WORKFLOW, WFT, } = useWebProperties();
  const getUser = useSelector((state) => state.auth);
  // eslint-disable-next-line
  const info = useSelector((state) => state.subtask);
  /* commented for update task 01 01-March-2024*/
  // const sprint = useSelector((state) => state.sprint); //updated with sprintId for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
  //For Open Chat
  const handleOpenChat = (action, id, us_id, sno, main_task_id, story_type) => {
    setChatOpen(true);
    // updateChat(sno,dispatch,getUser.user)
    var info = { action: action, id: us_id, sno: sno, main_task_id: main_task_id, story_type: story_type, us_id: us_id, };
    setCardInfo(info);
  };
  const priorityColorCodes = ['', '#FA8072', '#0BDA51', '#FFAA33', '#4682B4']
  //for Update Task

  /* commented for update task 01 01-March-2024*/
  // const handleOpenUpdate = (
  //   title,
  //   description,
  //   id,
  //   mainTaskId,
  //   dependencyId,
  //   sprintId,
  //   us_id,
  //   main_task_id,
  //   story_type
  // ) => {
  //   //updated with sprintId for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
  //   setUpdateOpen(true);
  //   var info = {
  //     title: title,
  //     description: description,
  //     id: id,
  //     mainTaskId: mainTaskId,
  //     dependencyId: dependencyId,
  //     sprintId: sprintId,
  //     us_id: us_id,
  //     main_task_id: main_task_id,
  //     story_type: story_type
  //   };
  //   setCardInfo(info);
  // };
  const handleChatClose = () => {
    setChatOpen(false);
    getAllUserStoryMessages(dispatch, getUser.user);
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  //for Update Task
  const handleOpenTaskInfo = (
    title,
    description,
    us_id,
    id,
    mainTaskId,
    dependencyId,
    timeLeft,
    complete_status,
    modifiedby,
    extraHours,
    mainTaskTitle,
    taskDesc,
    epic_name,
    laneId,
    moduleDesc,
    story_points,
    acceptanceCriteria,
    assignedDate,
    targetDate,
    modifiedDate,
    action,
    task_end_date,
    assignedto,
    assignedby,
    target_date,
    main_task_id,
    story_type,
    task_count,
    road_block_ids,
    combine_tasks,
    blocking_id
  ) => {
    if (story_type === 'group' && main_task_id === '0') {
      API.post("getUpdateSprint.php", {
        story_us_id: us_id,
        action: "get_group_task_info",
      })
        .then(function (response) {
          if (response.status === 'True') {
            setInfoOpen({ status: true, action: action });
            var info = {
              title: title,
              description: description,
              us_id: us_id,
              id: id,
              mainTaskId: mainTaskId,
              mainTaskTitle: mainTaskTitle,
              dependencyId: dependencyId,
              status: complete_status,
              modifiedby: modifiedby,
              timeLeft: timeLeft,
              extraHours: extraHours,
              taskDesc: taskDesc,
              ideaTitle: epic_name,
              laneId: laneId,
              moduleDesc: moduleDesc,
              storyPoints: story_points,
              acceptanceCriteria: acceptanceCriteria,
              assignedDate: assignedDate,
              targetDate: targetDate,
              modifiedDate: modifiedDate,
              action: action,
              openStatus: true,
              task_end_date: task_end_date,
              assignedto: assignedto,
              assignedby: assignedby,
              target_date: target_date,
              main_task_id: main_task_id,
              story_type: story_type,
              task_count: task_count,
              road_block_ids: road_block_ids,
              combine_tasks: combine_tasks,
              blocking_id: blocking_id,
              groupNames: response.data.map(item => {
                return item.fullname
              })
            };
            setCardInfo(info);
            store.dispatch(activeUserStory([info]));
          } else {
            console.error("Response status is not 'True'.");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      setInfoOpen({ status: true, action: action });
      var info = {
        title: title,
        description: description,
        us_id: us_id,
        id: id,
        mainTaskId: mainTaskId,
        mainTaskTitle: mainTaskTitle,
        dependencyId: dependencyId,
        status: complete_status,
        modifiedby: modifiedby,
        timeLeft: timeLeft,
        extraHours: extraHours,
        taskDesc: taskDesc,
        ideaTitle: epic_name,
        laneId: laneId,
        moduleDesc: moduleDesc,
        storyPoints: story_points,
        acceptanceCriteria: acceptanceCriteria,
        assignedDate: assignedDate,
        targetDate: targetDate,
        modifiedDate: modifiedDate,
        action: action,
        openStatus: true,
        task_end_date: task_end_date,
        assignedto: assignedto,
        assignedby: assignedby,
        target_date: target_date,
        main_task_id: main_task_id,
        story_type: story_type,
        task_count: task_count,
        road_block_ids: road_block_ids,
        combine_tasks: combine_tasks,
        blocking_id: blocking_id
      };
      setCardInfo(info);
      store.dispatch(activeUserStory([info]));
    }
  };
  const handleTaskInfoClose = () => {
    setInfoOpen(false);
  };
  const handleModalClose = () => {
    setChatOpen(false);
    getAllUserStoryMessages(dispatch, getUser.user); //For Chat Auto Refresh
  };
  // const getMessagesCount = (id, us_id, msg, empId) => {
  //   const msgCount = msg
  //     .filter(
  //       (message) =>
  //         message.readBy.split(",").indexOf(empId) === -1 &&
  //         message.messagedBy !== empId &&
  //         message.groupId === id
  //     )
  //     .map((messages, i) => {
  //       // eslint-disable-next-line
  //       return i, messages;
  //       // eslint-disable-next-line
  //     });
  // for getting unread message count
  const getMessagesCount = (id, us_id, msg, empId, main_task_id,
    story_type) => {
    const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1
      && message.messagedBy !== empId && message.groupId === us_id).map((messages, i) => {
        // eslint-disable-next-line
        return i, messages
      })
    return (
      <i>
        {/* {msgCount.length > 0 ? msgCount.length : null} */}
        {msgCount.length > 0 ? (
          <div className="row">
            <div >
              <img
                src="images/common/chat.svg"
                alt="logo"
                title={CHAT}
                style={{ width: "20px", height: "20px", marginLeft: "-8px" }}
                onClick={() => handleOpenChat("maintask", id, us_id, msgCount, main_task_id, story_type)}
              />
            </div>
            <div className="mr-2">
              <span style={{ color: "red", fontWeight: "bold" }}>
                {msgCount.length>9?"9+":msgCount.length}
              </span>
            </div>
          </div>
        ) : (
          <div className="row">
            <img
              src="images/common/chat.svg"
              alt="logo"
              title={CHAT}
              style={{ width: "20px", height: "20px", marginLeft: "-8px" }}
              onClick={() => handleOpenChat("maintask", id, us_id, msgCount, main_task_id, story_type)}
            />
          </div>
        )}
      </i>
    );
  };

  /* Task progress to be commented 02 01-March-2024 */
  // const getHours = (id, timesheet) => {
  //   const hoursCount = timesheet.filter(list => list.story_id === id).map((list) => {
  //     return list.sum;
  //   });
  //   if (hoursCount[0] > 0) {
  //     return Math.round(hoursCount[0]);
  //   }
  //   else {
  //     return 0;
  //   }
  // };

  // const getDays = (id, count_days_no_weekend) => {
  //   const daysCount = count_days_no_weekend.filter(list => list.story_id === id).map((list) => {
  //     return list.count_days_no_weekend;
  //   });
  //   if (daysCount[0] > 0) {
  //     return Math.round(daysCount[0]);
  //   }
  //   else {
  //     return 0;
  //   }
  // };

  return (
    <div
      onClick={onClick}
      // style={cardStyle}
      style={{
        border: "0.2px solid grey",
        borderRadius: "10px",
        padding: "10px",
        marginBottom: 2,
        backgroundColor: road_block_ids !== null && complete_status === 'pending' ? '#ededed' : 'white'
      }}
    // className={title === "Dispose Garbage" ? "bg-white" :"bg-primary"}
    >
      <header
        style={{
          display: "flex",
          color: cardColor,
          width: 260,
          padding: 0,
        }}
      >
        {laneId === "RoadBlock" ? (
          <div
            style={{
              fontSize: 8,
              fontWeight: "bold",
              cursor: "pointer",
              flex: 1,
            }}
            className="column "
            onClick={() =>
              handleOpenTaskInfo(
                title,
                story_desc,
                us_id,
                id,
                mainTaskid,
                dependencyId,
                timeLeft,
                complete_status,
                modifiedby,
                extraHours,
                mainTaskTitle,
                taskDesc,
                epic_name,
                laneId,
                moduleDesc,
                story_points,
                acceptanceCriteria,
                assignedDate,
                targetDate,
                modifiedDate,
                "roadblockInfo",
                task_end_date,
                assignedto,
                main_task_id,
                story_type,
                task_count,
                road_block_ids,
                combine_tasks,
                blocking_id
              )
            }
          >
            <div className="row d-flex justify-content-between">
              <div className="row pl-4">
                <u className="text-dark">
                  {getSubStringId(getUser.user.corp, 3)}-{" "}
                  {main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}
                  {us_id}
                </u>
                {combine_tasks !== false && combine_tasks !== undefined ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "#FF0000" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={`Combine Tasks`}
                  >
                    CT
                  </p>
                ) : null}
                {road_block_ids !== null ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "orange" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={`CT-${road_block_ids}`}
                  >
                    CT-{road_block_ids}
                  </p>
                ) : null}
                {epic_name !== null ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "orange" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={epic_name}
                  >
                    {getSubStringId(epic_name, 5)}
                  </p>
                ) : null}
                {story_type === 'group' ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "rgb(40, 210, 252)" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={story_type}
                  >
                    {getSubStringSprintId(story_type, 5)}
                  </p>
                ) : null}
                {task_count > 0 ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "rgb(143, 0, 255)" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={WORKFLOW}
                  >
                    {WFT}
                  </p>
                ) : null}
              </div>
              <div>
                <div style={{ display: 'inline-flex' }}>
                  <div
                    className="d-flex justify-content-center mt-2 mr-2"
                    style={{ backgroundColor: '#000080', width: 20, height: 20, cursor: 'pointer' }}
                    title={`Assigned by: ${assignedby}`}
                  >
                    {" "}
                    <i
                      className="text"
                      style={{
                        color: "white",
                        fontSize: 10,
                        fontWeight: "bold",
                        paddingTop: 3,
                      }}
                    >
                      {getSubStringId(assignedby, 2)}{" "}
                    </i>
                  </div>
                  <div
                    className="d-flex justify-content-center mt-2 mr-2"
                    style={{ backgroundColor: "#019be1", width: 20, height: 20, cursor: 'pointer' }}
                    title={`Assigned to: ${assignedto}`}
                  >
                    {" "}
                    <i
                      className="text"
                      style={{
                        color: "white",
                        fontSize: 10,
                        fontWeight: "bold",
                        paddingTop: 3,
                      }}
                    >
                      {getSubStringId(assignedto, 2)}{" "}
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <p className="text" style={{ width: 250 }}>
              {title}
            </p>
          </div>
        ) : (
          <div
            style={{
              fontSize: "30",
              fontWeight: "bold",
              cursor: "pointer",
              flex: 1,
            }}
            className="column"
            onClick={() =>
              handleOpenTaskInfo(
                title,
                subTaskDesc,
                us_id,
                id,
                mainTaskid,
                dependencyId,
                timeLeft,
                complete_status,
                modifiedby,
                extraHours,
                mainTaskTitle,
                taskDesc,
                epic_name,
                laneId,
                moduleDesc,
                story_points,
                acceptanceCriteria,
                assignedDate,
                targetDate,
                modifiedDate,
                "taskInfo",
                task_end_date,
                assignedto,
                assignedby,
                target_date,
                main_task_id,
                story_type,
                task_count,
                combine_tasks
              )
            }
          >
            <div className="row d-flex justify-content-between">
              <div className="row pl-4">
                <u className="text-dark">
                  {getSubStringId(getUser.user.corp, 3)}-{" "}
                  {main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}
                  {us_id}
                </u>
                {complete_status === 'verified' && <p data-toggle="tooltip" data-placement="left" title={'Verified by ' + modifiedby}>&nbsp;<span role="img" aria-labelledby="">✅</span></p>}
                {combine_tasks !== false && combine_tasks !== undefined ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "#FF0000" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={`Combine Tasks`}
                  >
                    CT
                  </p>
                ) : null}
                {road_block_ids !== null ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "orange" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={`CT-${road_block_ids}`}
                  >
                    CT-{road_block_ids}
                  </p>
                ) : null}
                {epic_name !== null ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ color: "orange" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={epic_name}
                  >
                    {getSubStringId(epic_name, 5)}
                  </p>

                ) : null}
                {story_type !== 'user' ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "rgb(40, 210, 252)" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={story_type}
                  >
                    {getSubStringSprintId(story_type, 5)}
                  </p>
                ) : null}
                {task_count > 0 ? (
                  <p
                    className="mb-0 ml-4"
                    style={{ marginRight: "2px", color: "rgb(143, 0, 255)" }}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={WORKFLOW}
                  >
                    {WFT}
                  </p>
                ) : null}
              </div>
              <div>
                <div style={{ display: 'inline-flex' }}>
                  <div
                    className="d-flex justify-content-center mt-2 mr-2"
                    style={{ backgroundColor: '#000080', width: 20, height: 20, cursor: 'pointer' }}
                    title={`Assigned by: ${assignedby}`}
                  >
                    {" "}
                    <i
                      className="text"
                      style={{
                        color: "white",
                        fontSize: 10,
                        fontWeight: "bold",
                        paddingTop: 3,
                      }}
                    >
                      {getSubStringId(assignedby, 2)}{" "}
                    </i>
                  </div>
                  <div
                    className="d-flex justify-content-center mt-2 mr-2"
                    style={{ backgroundColor: "#019be1", width: 20, height: 20, cursor: 'pointer' }}
                    title={`Assigned to: ${assignedto}`}
                  >
                    {" "}
                    <i
                      className="text"
                      style={{
                        color: "white",
                        fontSize: 10,
                        fontWeight: "bold",
                        paddingTop: 3,
                      }}
                    >
                      {getSubStringId(assignedto, 2)}{" "}
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <p className="text mb-0" style={{ width: 250 }}>
              {title}
            </p>
          </div>
        )}

        {/* <div style={{ fontSize: 11 }}>{dueOn}</div>
        {showDeleteButton && <button onClick={clickDelete} />} */}
      </header>
      <div
        className="column"
        style={{
          fontSize: 12,
          color: "#BD3B36",
          margin: 0,
          padding: 0,
          flex: 1,
        }}
      >
        {/* {laneId === "RoadBlock" ? <div style={{ color: '#4C4C4C', fontWeight: 'bold', width: '260px', overflowWrap: "break-word" }}>{taskDesc}</div> : <div style={{ color: '#4C4C4C', fontWeight: 'bold', width: '260px', overflowWrap: "break-word" }}>{subTaskDesc}</div>} */}
        <div className="row d-flex justify-content-between w-auto">
          <div className="row ml-2">
            {/* <p style={{ marginTop: 10, textAlign: 'right', color: cardColor, fontSize: 15, fontWeight: 'bold' }}>{taskStatus}</p> */}

            {/* <button style={{ border: '0', width: '10px', height: '40px', backgroundColor: 'transparent',  }} type="button" onClick={() => handleOpenChat("subtask", id)}> <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px',marginLeft:"-8px" }} /></button> */}
            <button
              type="button"
              style={{
                border: "0",
                height: "30px",
                backgroundColor: "transparent",
              }}
            >
              {getMessagesCount(
                id,
                us_id,
                info.allMessages,
                getUser.user.empId,
                main_task_id,
                story_type
              )}
            </button>
            {/* commented for update task 01 01-March-2024*/}
            {/* {complete_status === "pending" && activeStatus !== "0" ? (
              <button
                style={{
                  border: "0",
                  width: "8px",
                  height: "30px",
                  backgroundColor: "transparent",
                }}
                type="button"
                onClick={() =>
                  handleOpenUpdate(
                    title,
                    subTaskDesc,
                    id,
                    mainTaskid,
                    dependencyId,
                    sprint.activeSprint.moduleId,
                    us_id,
                    main_task_id,
                    story_type
                  )
                }
              >
                <img
                  src="images/common/update.svg"
                  alt="logo"
                  title={UPDATE_STORY}
                  style={{ width: "17px", height: "20px", marginLeft: "-17px" }}
                />
              </button>
            ) : null} */}
            {/*UserStory Comment  Box start GNK -->01 version 1.0.6 Start */}

            <button
              style={{
                border: "0",
                width: "8px",
                height: "30px",
                backgroundColor: "transparent",
              }}
              type="button"
              onClick={() =>
                handleOpenTaskInfo(
                  title,
                  subTaskDesc,
                  us_id,
                  id,
                  mainTaskid,
                  dependencyId,
                  timeLeft,
                  complete_status,
                  modifiedby,
                  extraHours,
                  mainTaskTitle,
                  taskDesc,
                  epic_name,
                  laneId,
                  moduleDesc,
                  story_points,
                  acceptanceCriteria,
                  assignedDate,
                  targetDate,
                  modifiedDate,
                  "commentInfo",
                  task_end_date,
                  assignedto,
                  main_task_id,
                  story_type
                )
              }
            >
              <img
                src="images/common/comments.svg"
                alt="logo"
                title={COMMENTS}
                style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
              />
            </button>

            {/* {complete_status === "completed" ? (
              <button
                style={{
                  border: "0",
                  width: "8px",
                  height: "30px",
                  backgroundColor: "transparent",
                }}
                type="button"
                onClick={() =>
                  handleOpenTaskInfo(
                    title,
                    subTaskDesc,
                    us_id,
                    id,
                    mainTaskid,
                    dependencyId,
                    timeLeft,
                    complete_status,
                    extraHours,
                    mainTaskTitle,
                    taskDesc,
                    epic_name,
                    laneId,
                    moduleDesc,
                    story_points,
                    acceptanceCriteria,
                    assignedDate,
                    targetDate,
                    modifiedDate,
                    "commentInfo"
                  )
                }
              >
                <img
                  src="images/common/comments.svg"
                  alt="logo"
                  style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
                />
              </button>
            ) : null} */}
            {/*UserStory Comment  Box start GNK -->01 version 1.0.6 End */}
            {/* Task progress to be commented 02 01-March-2024 */}
            {/* {activeStatus !== "0" ? (
              <div
                className="d-flex justify-content-center mr-1"
                title={USER_STORY_PROGRESS}
                style={{
                  backgroundColor: "#EDCB77",
                  width: 25,
                  height: 20,
                  cursor: 'pointer',
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: '0.45rem',
                  marginLeft: '-0.25rem',
                }}
              >
                {" "}
                <i
                  className="text"
                  style={{
                    color: "white",
                    fontSize: 10,
                    fontWeight: "bold",
                    paddingTop: 3,
                  }}
                >
                  {story_status}%
                </i>{" "}
              </div>
            ) : null} */}
            {/* {laneId !== "RoadBlock" ? ( */}
            {(blockedId != null && userStoryId !== null) ? (
              <p
                className="mt-2 ml-1"
                style={{ marginRight: "2px", color: "orange" }}
                data-toggle="tooltip"
                data-placement="left"
                title={userStoryId}
              >
                {getSubStringId(getUser.user.corp, 3)}-
                {userStoryId}
              </p>
            ) : null}
            {/* ) : null} */}

            {laneId === "RoadBlock" && blocking_id?.length > 0 ?
              <p
                className="mt-2 ml-1"
                style={{ marginRight: "2px", color: "red" }}
                data-toggle="tooltip"
                data-placement="left"
                title={userStoryId}
              >
                {
                  blocking_id[0].us_id !== null &&
                  `${getSubStringId(getUser.user.corp, 3)}-${blocking_id[0].us_id}`
                }
              </p>
              : null}
          </div>
          <div style={{ display: 'inline-flex' }}>
            <div
              className="d-flex justify-content-center mt-2 mr-2"
              style={{ backgroundColor: priorityColorCodes[priority_level], width: 20, height: 20, cursor: 'pointer' }}
              title={`${PRIORITY_LEVEL} ${priority_level}`}
            >
              {" "}
              <i
                className="text"
                style={{
                  color: "white",
                  fontSize: 10,
                  fontWeight: "bold",
                  paddingTop: 3,
                }}
              >
                {/* {priority_level}{" "} */}
              </i>
            </div>
            {/* story points and working days (or) Hours 03 01-March-2024 */}
            {/* <div
              className="d-flex justify-content-center mt-2 mr-2"
              style={{ backgroundColor: 'green', width: 20, height: 20, cursor: 'pointer' }}
              title={complete_status === "pending" && activeStatus === "0" ? "Days Spent" : "Hours Spent"}
            >
              {" "}
              <i
                className="text"
                style={{
                  color: "white",
                  fontSize: 10,
                  fontWeight: "bold",
                  paddingTop: 3,
                }}
              >
                {complete_status === "pending" && activeStatus === "0" ? getDays(id, info.workingDays) : getHours(id, info.workingHours)}
              </i>
            </div>
            <div
              className="d-flex justify-content-center mt-2 mr-2"
              style={{ backgroundColor: "#6DAE8C", width: 20, height: 20, cursor: 'pointer' }}
              title={STORY_POINTS}
            >
              {" "}
              <i
                className="text"
                style={{
                  color: "white",
                  fontSize: 10,
                  fontWeight: "bold",
                  paddingTop: 3,
                }}
              >
                {story_points}{" "}
              </i>
            </div> */}
          </div>
        </div>
        {tags && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {/* {tags.map(tag => (
                <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
              ))} */}
          </div>
        )}
      </div>
      {chatOpen ? (
        <ChatBox
          open={chatOpen}
          handleClose={handleChatClose}
          data={cardInfo}
          handleModalClose={handleModalClose}
        />
      ) : null}
      {updateOpen ? (
        <UpdateTask
          open={updateOpen}
          handleClose={handleUpdateClose}
          data={cardInfo}
          handleModalClose={handleModalClose}
        />
      ) : null}
      {infoOpen.status && infoOpen.action === "commentInfo" ? (
        <TaskInfo
          open={infoOpen}
          handleClose={handleTaskInfoClose}
          data={cardInfo}
          handleModalClose={handleModalClose}
        />
      ) : null}
      {infoOpen.status && infoOpen.action === "roadblockInfo" ? (
        <RoadBlockModal
          open={infoOpen}
          handleClose={handleTaskInfoClose}
          data={cardInfo}
          handleModalClose={handleModalClose}
        />
      ) : null}
    </div>
  );
};

export default function UserSprints() {
  const openmodal = {
    backgroundColor: "white",
    // height: 570,
    // marginLeft: "-10px",
    // marginRight: "-10px",
    // width: 1000,
    // margin: 20,
  };

  const closemodal = {
    backgroundColor: "white",
    // height: 570,
    // marginLeft: "-10px",
    // marginRight: "-10px",
    // margin: 20,
  };

  const [state, dispatch] = useReducer(moduleReducer, initialState);
  const { ROADBLOCK, NEWMODULE, ALL_USERS, LIMITED_ACCESS_CONTRIBUTOR, MODULES, SCRUM_MASTER, PRODUCT_OWNER,
    ACTIVE_SPRINT, SCRUM_BOARD, MAINTASK_DESCRIPTION, STORY_POINTS, MAINTASK, ACCEPTANCE_CRITERIA, MODULE_ADD, MODULE,
    DEFINITION_OF_DONE, PROJECTNAME, ASSIGNED_DATE, PROJECT_COMPLETED_DATE, TARGET_DATE, ASSIGNED_TO, ASSIGNED_BY,
    role_array, TASKS_WARNING, ROADBLOCK_WARNING, COMBINE_TASKS } = useWebProperties();
  const getUser = useSelector((state) => state.auth);
  // const loaderStatus = useSelector(state => state.loading.status)
  const [open, setOpen] = useState(false);
  const [cardInfo, setCardInfo] = useState();
  const [openModule, setOpenModule] = useState({ status: false, index: 0 });
  const [moduleInfo, updateModuleInfo] = useState();
  //   const [open, setOpen] = useState({ status: false, index: 0 })
  //   const dispatch = useDispatch();
  const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

  // useEffect(() => {
  //   // fun()
  //   checkToken()
  //   // eslint-disable-next-line
  // }, [])
  // const checkToken = () => {
  //   if (getToken('auth')) {
  //     const tokenDetails = jwt_decode(getToken('auth'));
  //     store.dispatch(setCurrentUser(tokenDetails.data)); //store the user information
  //     const currentTime = Date.now() / 1000; // to get in milliseconds
  //     if (tokenDetails.exp < currentTime) {
  //       localStorage.removeItem('auth');
  //       // Remove auth header for future requests
  //       delete axios.defaults.headers.common['x-access-token'];
  //       // Set current user to empty object {} which will set isAuthenticated to false
  //       clearToken();
  //       localStorage.removeItem('persist:main-root');
  //       store.dispatch(setCurrentUser({}));
  //       window.location.href = "/";
  //     }
  //   }
  // }

  useEffect(() => {
    store.dispatch(todo([]));
    store.dispatch(todoFilter([]));
    store.dispatch(doing([]));
    store.dispatch(doingFilter([]));
    store.dispatch(done([]));
    store.dispatch(doneFilter([]));
    store.dispatch(roadblock([]));
    store.dispatch(roadblockFilter([]));
    getActiveSprints(dispatch, getUser.user);
    getModules(dispatch, getUser.user, MODULE_ADD, MODULE);
    // getToDoUserStories(dispatch, getUser.user); //for todo and doing information
    // getRoadBlockUserStories(dispatch, getUser.user);
    // getDoneUserStories(dispatch, getUser.user);
    getAllUserStoryMessages(dispatch, getUser.user);
    // getWorkingHours(dispatch, getUser.user);
    // getWorkingDays(dispatch, getUser.user);
    store.dispatch(activeUserStory([{ openStatus: false }]));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchUserStoryMessages = () => {
      getAllUserStoryMessages(dispatch, getUser.user);
    };

    // Function to fetch messages and schedule the next fetch after 10 seconds
    const fetchDataAndScheduleNext = () => {
      fetchUserStoryMessages();
      setTimeout(fetchDataAndScheduleNext, 10000); // Schedule next fetch after 10 seconds
    };

    // Initial fetch when component mounts
    fetchUserStoryMessages();

    // Schedule the next fetch after 10 seconds
    const timerId = setTimeout(fetchDataAndScheduleNext, 10000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timerId);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const timer = setInterval(() => {
      getActiveSprints(dispatch, getUser.user, false);// for active sprint user stories
      // getWorkingHours(dispatch, getUser.user, false);
      // getWorkingDays(dispatch, getUser.user, false);
      // getModules(dispatch, getUser.user,false);
      getAllUserStoryMessages(dispatch, getUser.user, false);//messages
      // store.dispatch(activeUserStory([{ openStatus: false }]));
    }, 60 * 3000);
    return () => {
      clearInterval(timer); // Return a function to clear the timer so that it will stop being called on unmount
    }
    // eslint-disable-next-line     
  }, []);
  // console.log(state.sprint)
  const info = useSelector((state) => state.sprint);
  // console.log(info.workingHours);
  var data = {
    lanes: [
      {
        id: "ToDo",
        title: "To Do",
        style: {
          width: 310,
          // height: 550,
          fontSize: 15,
          fontWeight: "bold",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "3px 3px 20px 10px #f1f1f1",
          margin: "7px",
        },
        cards: filteredData(getCompleteData(info.todoFilter.filter(item => {
          return item.road_block_ids === null
        }), info.groupTodo, getUser)),
      },
      {
        id: "Doing",
        title: "In Progress",
        // "label": "10/20",
        style: {
          width: 310,
          // height: 550,
          fontSize: 15,
          fontWeight: "bold",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "3px 3px 20px 10px #f1f1f1",
          margin: "7px",
        },
        cards: filteredData(getCompleteData(info.doingFilter.filter(item => {
          return item.road_block_ids === null
        }), info.groupDoing, getUser)),
      },
      {
        id: "RoadBlock",
        title: ROADBLOCK,
        // "label": "0/0",
        style: {
          width: 310,
          // height: 550,
          fontSize: 15,
          fontWeight: "bold",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "3px 3px 20px 10px #f1f1f1",
          margin: "7px",
        },
        cards: filteredData(getCompleteRoadblockData(info.roadblockFilter.filter(item => {
          return item.road_block_ids === null
        }), info.groupRoadblock, getUser)),
      },
      {
        id: "Completed",
        title: "Done",
        style: {
          // width: 310,
          // height: 550,
          fontSize: 15,
          fontWeight: "bold",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "3px 3px 20px 10px #f1f1f1",
          margin: "7px",
        },
        // "label": "2/5",
        cards: filteredData(getCompleteData(info.doneFilter, info.groupDone, getUser)),
      },
    ],
  };
  // console.log(info.todo)
  function handleCardMove(from, to, data, sprintId, doing) {
    //updated with "sprintId" for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
    // setOpen(true);
    // console.log("story_id" + data);
    const check = from + to;
    // console.log("sprint_id" + sprintId);
    var message;
    if (check === "ToDoDoing") {
      UserStatus('Available')
      message = `${MAINTASK} in progress`;
      getActiveUserStory(
        dispatch,
        getUser.user,
        data,
        sprintId,
        handleClose,
        handleOpen,
        message,
        getUser.user.corp
      ); //updated with "sprintId" for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
      // addCommentUserStory(dispatch, getUser.user, data, message, "1");
    } else if (check === "ToDoRoadBlock") {
      UserStatus('Available')
      Alert("warning", "You are not able to add RoadBlock from To Do");
      // Alert("warning", "ToDoRoadBlock"+from + to + data);
    } else if (check === "DoingRoadBlock") {
      UserStatus('Available')
      message = `${MAINTASK} is blocked`;
      var info = { taskId: data, message: message, sprintId: sprintId, kanban_status: "0" };
      setCardInfo(info);
      setOpen(true);
    } else if (check === "DoingCompleted") {
      UserStatus('Available')
      message = `${MAINTASK} is completed`;
      updateUserStory(dispatch, getUser.user, data, sprintId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING); //updated with sprintId for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
      // addCommentUserStory(dispatch, getUser.user, data, message, "2");
    } else if (check === "DoingToDo") {
      const doingList = doing.filter((doing) => (accessRole ? doing.story_id === data : ((doing.assigned_to === getUser.user.empId || doing.assigned_by === getUser.user.empId) && doing.story_id === data))) || [];
      if (doingList.length === 1) {
        updateUserstotyStaus(state, dispatch, getUser.user, '', data, doingList[0].sprint_id, doingList[0].assigned_to, handleClose, MAINTASK)
      } else {
        allUserBacklogs();
        Alert("warning", `You do not have an access to move this ${MAINTASK} into ToDo, please contact your ${PRODUCT_OWNER} (or) ${SCRUM_MASTER}.`);
      }
    } else {
      // Alert("warning", from + to + data);
    }
  }
  const handleOpen = (action, data) => {
    var info;
    setOpenModule({ status: true, action: action });

    if (action === "addSprint") {
      info = { action: action };
    } else {
      info = {
        action: action,
        inprogress: data.inprogressStoryId,
        todo: data.todoStoryId,
        currentSprint: data.currentSprint,
        assignedTo: data.assignedTo,
        projectName: data.projectName,
        story_title: data.story_title,
        inprogress_story_title: data.inprogress_story_title,
        us_id: data.us_id,
        todo_us_id: data.todo_us_id,
        toDoProjectName: data.toDoProjectName
      }
    }

    updateModuleInfo(info);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenModule({ status: false });
    getCurrentSprintUserStories(
      dispatch,
      getUser.user,
      info.activeSprint.moduleId
    ); //updated with "sprintId" and method name changed from getActiveSprint() for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
    getModules(dispatch, getUser.user);
    getAllUserStoryMessages(dispatch, getUser.user);
  };
  const handleModalClose = () => {
    setOpen(false);
    setOpenModule({ status: false });
  };
  // console.log(state.modules)
  var sprints = [];
  state.modules.map((module) => {
    return sprints.push({
      value: module.moduleId,
      label: module.moduleDesc,
      assignbyId: module.assignbyId,
      average: module.average,
      createdBy: module.createdBy,
      created_on: module.created_on,
      ideaId: module.ideaId,
      ideaTitle: module.ideaTitle,
      moduleDesc: module.moduleDesc,
      moduleId: module.moduleId,
      startDate: module.startDate,
      sprint_status: module.sprint_status,
      targetDate: module.targetDate,
      task_end_date: module.task_end_date,
      assinedto: module.assignedto,
      assignedby: module.assignedby,
      target_date: module.target_date
    });
  });
  const selectSprint = (selectedOption) => {
    // console.log(selectedOption.moduleId)
    dispatch(sprintSelected(selectedOption.value));
    store.dispatch(activeSprint(selectedOption));
    getToDoUserStories(dispatch, getUser.user, selectedOption.moduleId);
    getAllUserStoryMessages(dispatch, getUser.user);
    getRoadBlockUserStories(dispatch, getUser.user, selectedOption.moduleId);
    getDoneUserStories(dispatch, getUser.user, selectedOption.moduleId);
    getInvolvedEmployees(dispatch, getUser.user, selectedOption.moduleId);
    store.dispatch(activeUserStory([{ openStatus: false }]));
  };
  const allUserBacklogs = () => {
    store.dispatch(todoFilter(info.todo));
    store.dispatch(doingFilter(info.doing));
    store.dispatch(doneFilter(info.done));
    store.dispatch(roadblockFilter(info.roadblock));
    store.dispatch(activeUserStory([{ openStatus: false }]));
  };

  const UserStatus = async (status) => {
    try {
      const response = await API.post("user_status.php", {
        corp: getUser.user.corp,
        action: 'update_status',
        status: status,
        empId: getUser.user.empId
      }, {}, false);
      if (response.status === 'True') {

        getUser.user.empStatus = status
        dispatch(setCurrentUser(getUser.user));

      } else {
        dispatch(setCurrentUser(getUser.user));
      }
    } catch (error) {
      // Alert('error',error.message)
    }
    // dispatch(isLoaded());
  }
  const userBacklogs = (assignedToId, moduleId) => {
    // console.log(assignedToId + "   " + moduleId)
    var todoList = [];
    var doingList = [];
    var doneList = [];
    var roadBlockList = [];
    store.dispatch(activeUserStory([{ openStatus: false }]));
    // info.todo.map((todo) => {
    //   console.log(assignedToId)
    //   return todo.assignedTo === assignedToId ? todoList.push(todo) : [];
    // });
    // info.doing.map((doing) => {
    //   return doing.assignedTo === assignedToId ? doingList.push(doing) : [];
    // });
    // info.done.map((done) => {
    //   return done.assignedTo === assignedToId ? doneList.push(done) : [];
    // });
    // info.roadblock.filter((roadblock) => {
    //   return roadblock.assignedTo === assignedToId
    //     ? roadBlockList.push(roadblock)
    //     : [];
    // });

    todoList = info.todo.filter((todo) => (todo.assignedTo === assignedToId));
    doingList = info.doing.filter((doing) => (doing.assignedTo === assignedToId));
    doneList = info.done.filter((done) => (done.assignedTo === assignedToId));
    roadBlockList = info.roadblock.filter((roadblock) => (roadblock.assigned_to === assignedToId));
    store.dispatch(todoFilter(todoList));
    store.dispatch(doingFilter(doingList));
    store.dispatch(doneFilter(doneList));
    store.dispatch(roadblockFilter(roadBlockList));
  };
  // console.log(info.activeUserStory[0])
  return (
    <div className="container-scroller">
      <TopNav />
      <div className="container-fluid page-body-wrapper">
        <SideBar />
        <div className="main-panel">
          {/* <div className="content-wrapper"> */}
          {state.modules.length > 0 ? (
            <div style={{ flexDirection: "row" }}>
              <div className="col-12 row p-2">
                <div
                  className="col-lg-6 col-sm-12"
                  style={{
                    overflowWrap: "break-word",
                    color: "blue", marginTop: '5px',
                    backgroundColor: "transparent",
                  }}

                >
                  <b style={{ cursor: "pointer" }} title={Moment(info.activeSprint.startDate).format("MM.DD.YYYY") + "-" + Moment(info.activeSprint.targetDate).format("MM.DD.YYYY")}>
                    {getSubStringId(getUser.user.corp, 3)}{" "}
                    {info.activeSprint.moduleDesc}
                  </b>{""}{" - "}{SCRUM_BOARD}

                  {info.activeSprint.sprint_status === 'commited' &&
                    <b style={{ marginLeft: '10px', marginTop: '5px', backgroundColor: '#E9967A', color: 'white', padding: '5px', borderRadius: '5px', height: '25px' }}>COMMITTED</b>
                  }
                </div>

                <label className="p-2">{MODULES}</label>
                <Select
                  className="form-control col-lg-3 col-sm-12"
                  style={{ width: 10, boarderRadius: 2 }}
                  // placeholder="Select Active Points"
                  placeholder={info.activeSprint.moduleDesc}
                  value={sprints.value}
                  onChange={(selectedOption) => selectSprint(selectedOption)}
                  options={sprints}
                />
                <div className="mb-2 ">
                  {(role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER) && <button
                    style={{ backgroundColor: "transparent", border: "0" }}
                    type="button"
                    onClick={() => handleOpen("addSprint", "")}
                  >
                    {" "}
                    <img
                      src="images/common/add.png"
                      alt="logo"
                      title={NEWMODULE}
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span className="m-1">{NEWMODULE}</span>
                  </button>}
                  {openModule.action === "addSprint" ? (
                    <AddModule
                      open={openModule.status}
                      handleClose={handleClose}
                      data={moduleInfo}
                      handleModalClose={handleModalClose}
                    />
                  ) : null}
                </div>
              </div>

              {getUser.user.role !== LIMITED_ACCESS_CONTRIBUTOR && <h6 className="col-lg-12 row" >
                <div style={{ marginTop: -25, marginLeft: 10 }}>
                  <input
                    type="image"
                    src="images/common/teams.png"
                    title={ALL_USERS}
                    style={{
                      textTransform: "capitalize",
                      padding: 1,
                      textAlign: "center",
                      marginTop: 25,
                    }}
                    alt="logo"
                    width="25"
                    height="25"
                    onClick={() => allUserBacklogs()}
                  />
                </div>
                {state.involvedEmployees.map((involved, index) => {
                  const input = involved.assignedTo;
                  const [name] = input.split("@");
                  return (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        padding: "1px",
                        textAlign: "center",
                        marginLeft: "10px",
                        color: "green",
                      }}
                      onClick={() =>
                        userBacklogs(
                          involved.assignedToId,
                          info.activeSprint.moduleId
                        )
                      }
                      title={name + "\n Pending Points:" + involved.pending_points + " \nCompleted Points:" + involved.completed_points}
                    >
                      {getSubStringId(name, 2)}-{involved.points}{" "}
                    </div>
                  );
                })}{" "}
              </h6>}
            </div>
          ) : (
            <div className="col-12 row p-2">
              <h5
                className="col-6"
                style={{
                  overflowWrap: "break-word",
                  color: "blue",
                  backgroundColor: "transparent",
                }}
              >
                {state.isLoading || info.activeSprint.length !== 0 ? null : <div className="" style={{ marginLeft: '40%', textAlign: 'end', marginTop: '30%' }}>
                  {/* No Active Sprint */}
                  <img
                    src="images/common/nosprint.png"
                    alt="logo"
                    style={{}}
                  />
                  <b className="ml-3">No {ACTIVE_SPRINT}</b>
                </div>
                }
              </h5>

              <label className="p-2">{MODULES}</label>
              <Select
                className="form-control col-lg-3 col-sm-12"
                style={{ width: 10, boarderRadius: 2 }}
                // placeholder="Select Active Points"
                placeholder={info.activeSprint.moduleDesc}
                value={sprints.value}
                onChange={(selectedOption) => selectSprint(selectedOption)}
                options={sprints}
              />

              <div className="mb-2 ">
                {(role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER) && <button
                  style={{ backgroundColor: "transparent", border: "0" }}
                  type="button"
                  onClick={() => handleOpen("addSprint", "")}
                >
                  {" "}
                  <img
                    src="images/common/add.png"
                    title={NEWMODULE}
                    alt="logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <span className="m-1">{NEWMODULE}</span>
                </button>}
                {openModule.action === "addSprint" ? (
                  <AddModule
                    open={openModule.status}
                    handleClose={handleClose}
                    data={moduleInfo}
                    handleModalClose={handleModalClose}
                  />
                ) : null}
              </div>
            </div>
          )}
          {state.isLoading ? (
            <RootLoader />
          ) : (
            <div className="row" style={{ flexDirection: "row" }}>
              <div
                className={
                  info.activeUserStory[0].openStatus &&
                    info.activeUserStory[0].action === "taskInfo"
                    ? "col-9"
                    : "col-12 arc"
                }
              >
                {info.activeSprint.length !== 0 ?
                  <Board
                    tagStyle={{ fontSize: "20%", textAlign: "left" }}
                    data={data}
                    draggable
                    // collapsibleLanes
                    onCardMoveAcrossLanes={(cardId, metadata, card) =>
                      handleCardMove(
                        cardId,
                        metadata,
                        card,
                        info.activeSprint.moduleId,
                        info.doingFilter
                      )
                    }
                    components={{ Card: CustomCard }} //custom cards
                    cardDragClass="draggingCard"
                    laneDragClass="draggingLane"
                    // onCardMoveAcrossLanes={onCardMoveAcrossLanes}
                    style={
                      info.activeUserStory[0].openStatus &&
                        info.activeUserStory[0].action === "taskInfo"
                        ? openmodal
                        : closemodal
                    } //,height:400
                  // laneStyle={{backgroundColor: '#666'}} style={{backgroundColor: '#eee'}}
                  />
                  : null}
              </div>
              {info.activeUserStory[0].openStatus &&
                info.activeUserStory[0].action === "taskInfo" ? (
                <div
                  className={
                    info.activeUserStory[0].openStatus ? "col-3 mt-4 tnj" : null
                  }
                  style={{
                    padding: 10,
                    borderLeft: "1px solid grey",
                    borderBottom: "1px solid grey",
                    borderRight: "1px solid grey",
                    borderTop: "1px solid grey",
                    backgroundColor: "#f8f8f8",
                    // height: 550,
                    overflowY: "scroll",
                  }}
                >
                  <div style={{ flexDirection: "row" }}>
                    <text
                      style={{ fontWeight: "bold", fontSize: 14, width: 30 }}
                    >
                      {getSubStringId(getUser.user.corp, 3)} -{" "}
                      {info.activeUserStory[0].main_task_id !== null && info.activeUserStory[0].main_task_id !== "0" ? `${info.activeUserStory[0].main_task_id}-` : ''}
                      {info.activeUserStory[0].us_id}
                    </text>
                    <button
                      style={{ backgroundColor: "transparent", border: "0" }}
                      type="button"
                      data-dismiss="modal"
                      onClick={() =>
                        store.dispatch(activeUserStory([{ openStatus: false }]))
                      }
                    >
                      <i class="mdi mdi-close text-black"></i>
                    </button>
                  </div>
                  {/* <hr /> */}
                  {/* <div>
                                    <text style={{fontSize:12}}>{info.activeUserStory[0].id}</text>
                                </div> */}

                  <div>
                    <text style={{ fontWeight: "bold", fontSize: 12 }}>
                      {" "}
                      {PROJECTNAME}
                    </text>
                  </div>
                  <div>
                    <text style={{ fontSize: 12 }}>
                      {info.activeUserStory[0].ideaTitle}
                    </text>
                  </div>

                  <div className="pt-2">
                    <text style={{ fontWeight: "bold", fontSize: 12 }}>
                      {" "}
                      {MAINTASK} Title
                    </text>
                  </div>
                  <div>
                    <text style={{ fontSize: 12 }}>
                      {info.activeUserStory[0].title}
                    </text>
                  </div>

                  <div className="pt-2">
                    <text style={{ fontWeight: "bold", fontSize: 12 }}>
                      {MAINTASK_DESCRIPTION}
                    </text>
                  </div>
                  <div>
                    <text style={{ fontSize: 12, whiteSpace: 'break-spaces' }}>
                      {info.activeUserStory[0].description}
                    </text>
                  </div>

                  <div className="pt-2">
                    <text style={{ fontWeight: "bold", fontSize: 12 }}>
                      {STORY_POINTS}
                    </text>
                  </div>
                  <div>
                    <text style={{ fontSize: 12 }}>
                      {info.activeUserStory[0].storyPoints}
                    </text>
                  </div>

                  <div className="pt-2">
                    <text style={{ fontWeight: "bold", fontSize: 12 }}>
                      {ACCEPTANCE_CRITERIA}/{DEFINITION_OF_DONE}
                    </text>
                  </div>
                  <div>
                    <text style={{ fontSize: 12, whiteSpace: 'break-spaces' }} >
                      {info.activeUserStory[0].acceptanceCriteria}
                    </text>
                  </div>

                  <div>
                    <div className="pt-2">
                      <text style={{ fontWeight: "bold", fontSize: 12 }}>
                        {ASSIGNED_DATE}
                      </text>
                    </div>
                    <div>
                      <text style={{ fontSize: 12 }}>
                        {convertPSTtoLocalTime(info.activeUserStory[0].assignedDate)}
                      </text>
                    </div>
                  </div>
                  <div>
                    <div className="pt-2">
                      <text style={{ fontWeight: "bold", fontSize: 12 }}>
                        {ASSIGNED_TO}
                      </text>
                    </div>
                    <div>
                      <text style={{ fontSize: 12 }}>
                        {info.activeUserStory[0].assignedto} {info.activeUserStory[0]?.groupNames?.length > 0 && info.activeUserStory[0].groupNames.join(',')}
                      </text>
                    </div>
                  </div>
                  <div>
                    <div className="pt-2">
                      <text style={{ fontWeight: "bold", fontSize: 12 }}>
                        {ASSIGNED_BY}
                      </text>
                    </div>
                    <div>
                      <text style={{ fontSize: 12 }}>
                        {info.activeUserStory[0].assignedby}
                      </text>
                    </div>
                  </div>
                  <div>
                    <div className="pt-2">
                      <text style={{ fontWeight: "bold", fontSize: 12 }}>
                        {TARGET_DATE}
                      </text>
                    </div>
                    <div>
                      <text style={{ fontSize: 12 }}>
                        {info.activeUserStory[0].target_date}
                      </text>
                    </div>
                  </div>
                  {
                    info.activeUserStory[0].road_block_ids !== false && info.activeUserStory[0].road_block_ids !== undefined
                      ?
                      <div>
                        <div className="pt-2">
                          <text style={{ fontWeight: "bold", fontSize: 12 }}>
                            {COMBINE_TASKS}
                          </text>
                        </div>
                        {
                          info.activeUserStory[0].road_block_ids?.map(item => {
                            return <div>
                              <text style={{ fontSize: 12 }}>
                                {getSubStringId(getUser.user.corp, 3)}-{item.us_id}-{item.story_title}
                              </text>
                            </div>

                          })
                        }

                      </div>
                      : null
                  }
                  {info.activeUserStory[0].status === "completed" ? (
                    <div>
                      <div className="pt-2">
                        <text style={{ fontWeight: "bold", fontSize: 12 }}>
                          {PROJECT_COMPLETED_DATE}
                        </text>
                      </div>
                      <div>
                        <text style={{ fontSize: 12 }}>
                          {convertPSTtoLocalTime(info.activeUserStory[0].task_end_date)}
                        </text>
                      </div>
                    </div>
                  ) : null}
                  <SubTasksInfo data={info.activeUserStory[0]} />
                </div>
              ) : null}
            </div>
          )}
          {/* </div> */}
          {open ? (
            <RoadBlock
              open={open}
              handleClose={handleClose}
              data={cardInfo}
              handleModalClose={handleModalClose}
            />
          ) : null}
          {openModule.action === "changeUserstoryStatus" && moduleInfo != null ? (
            <ChangeUserstoryStatus
              open={openModule.status}
              handleClose={handleClose}
              data={moduleInfo}
              handleModalClose={handleModalClose}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
