// common/services/addMainTask.js  (CREATE) from MyTasker

import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

import { createMainTask } from "../../../Common/TasksModals/Services/createMainTask";



export async function addMainTask(state, dispatch, getUser,handleClose, pt_id,storyTitle,ADD_TO_TODO_PROJECTS, action, MAINTASK_ADD) {
  if (state.userSelected.from === 'group') {
    createMainTask(state, dispatch, getUser, handleClose, MAINTASK_ADD)
  }
  else if(state.agileProjectSelected !== ""){
   if (state.taskTitle.value && state.taskTitle.value.trim() !== "" && state.taskDescription.value && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value && state.acceptanceCriteria.value.trim() !== "") {
    dispatch(isLoading());
    try {
      var response = await API.post("private_todo.php", {
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_by: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        added_to: state.userSelected === "" ? (state.id.value || getUser.empId) : state.userSelected.value,
        storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
        priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
        idea_id: state.epicSelected === "" ? (state.ideaId.value || '') : state.epicSelected,
        projectId:state.agileProjectSelected,
        device_id: state.device_id,
        pt_id:pt_id
      }, {}, false);
      if (response.status === 'True') {
        const message = action === "addUserStory" ? MAINTASK_ADD : ADD_TO_TODO_PROJECTS;
        Alert("success", message);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
 
  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}else{
  Alert("warning", "Please select the Project ")
  dispatch(isLoaded());
}
} 