import ReportsAPI from "../../AgileNetwork/ReportsAPI";
import * as actions from "../actions";

const modify_list = (points_list)=>{
    const modified_points=points_list.map((line,index)=>{
        return `${index + 1}. ${line}`;
    })
    return modified_points.join('\n');
}

const getAiTaskDetails= async (task_description,dispatch,setIsLoading)=>{
    try {
        setIsLoading(true);
        var response= await ReportsAPI.post("/ai/getAiData",{task_detail:task_description},{},false);
        //var response= await ReportsAPI.post("https://pgtestreporting.agile24x7.com/ai",{task_detail:task_description},{},false);
        const description= response?.task_details?.description;
        const acceptance = modify_list(response?.task_details?.acceptance);
        const task_title = response?.task_details?.task_name;
        
        dispatch(actions.taskTitle(String(task_title)));
        dispatch(actions.taskDescription(String(description)));
        dispatch(actions.acceptanceCriteria(String(acceptance)));
        setIsLoading(false);

    } catch (error) {
        setIsLoading(false);
        console.log('error', error.message);
    }
}

export default getAiTaskDetails;