import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getSubTasks } from "./getSubtask";

export async function createWorkflowTemplate(state, dispatch, getUser, mainTaskId, moduleId,assignedTo, handleClose,SUBTASK_ADD, SUBTASK, project_id) {
  dispatch(isLoading());
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "" ) {
    try {
      var response = await API.post("tasks_workflow.php", {
        assignedBy: getUser.empId,
        assignedTo: state.userSelected || getUser.empId,
        "projectId": (project_id || getUser.projectId),
        maintaskId: mainTaskId,
        moduleId: moduleId,
        crop: getUser.corp,
        action: "workflowTemplateOnly",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        targetDate:state.targetDate.value,
        acceptanceCriteria: state.acceptanceCriteria.value,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(getSubTasks(dispatch, getUser, mainTaskId, project_id))
        Alert("success", SUBTASK_ADD);
      }
      else{
        Alert('warning', `${SUBTASK} Already Existed!`);
      }
    } catch (error) {
      Alert('warning', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "please give task information")
  }
}