// userprofile/services/createprivateToDo.js (CREATE)

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded } from "../actions";

export async function createPrivateToDo(state, dispatch, getUser,handleClose, MAINTASK) {
  dispatch(isLoading());
   if (state.taskTitle.value.trim() !== "" ) {
    try {
      var response = await API.post("personal_tasks.php", {
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_to: state.userSelected || getUser.empId,
        added_by: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        storyPoints: state.storySelected,
        priorityLevel: state.prioritySelected,  
      }, {}, false);
      if (response.status === 'True') {
        Alert("success", `${MAINTASK} added successfully`);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
 
  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}