/*
filename: DelegateTask.js
purpose:To Delegate user story
Developers: G Naveen Kumar[G.N.K]
 */
import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as actions from './actions'
import { useSelector } from 'react-redux';
import { getUsers } from './Services/getUsers';
import { tasksReducer, initialState } from './tasksReducer';

import MainTaskChatBox from '../ChatMainTask';
import { useWebProperties } from '../webProperties';
import { delegateMainTask } from './Services/delegateTask';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function DelegateTask(props) {

    const [state, dispatch] = useReducer(tasksReducer, initialState);
    const getUser = useSelector(state => state.auth)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState();
    const classNames = useStyles();
    const { MODULE } =  useWebProperties();
    useEffect(() => {
        getUsers(dispatch, getUser.user, props.data.projectId);
        dispatch(actions.setModifyMainTask(props.data.taskId, props.data.title, props.data.description, props.data.ideaId, props.data.moduleId, props.data.acceptanceCriteria, props.data.storyPoints, props.data.id, props.data.priorityLevel, props.data.targetDate, props.data.device_id, props.data.player_id))
        // eslint-disable-next-line
    }, [])

    var userDetails = [];

    state.users.filter((({id}) => id !== getUser.user.empId)).map((users) => {
        return (
            users.workingStatus === "Active" ?
                userDetails.push({ 'value': users.id, 'label': users.name, 'device_id': users.device_id, player_id: users.player_id, 'from': 'user' }) : null
        );
    })

   const generateColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0');
        return `#${randomColor}`;
      };

      const handleChatOpen = async (action, index, sno, userInfo) => {
        var info;
        setOpen({ status: true, action: action });
        if (action === "maintask") {
            delegateMainTask(props.data.targetDate, state, dispatch, getUser.user, props.data.ideaId, props.data.moduleId, props.handleClose, MODULE, userInfo)
            info = {
                action: action, id: index.us_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
                story_type: index.story_type, us_id: index.us_id
            }
        }
        setCardInfo(info);
    }
    
    const handleChatClose = () => {
        props.handleClose();
        setOpen({ status: false, index: 0 })
    };
    return (
        <div>
            {open.action !== "maintask" && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleChatClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-4 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: 'rgb(234, 234, 234)' }}>
                                    {<h5 class="modal-title p-2">Delegate To</h5>}
                                    <div style={{ display: 'flex' }}>
                                        {/* {userDetails.length === 0 ? <RootLoader /> :
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { reassignMainTask(props.data.backlogs, props.data.activeStatus, props.data.currentDate, props.data.sprintTargetDate, state, dispatch, getUser.user, props.data.ideaId, props.data.moduleId, props.handleClose, MODULE) }}>Reassign</button>
                                            </div>} */}
                                        <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                    </div>
                                </div>
                                <div className="modal-body overflow-auto" style={{ display: 'inline-flex', flexWrap: 'wrap', maxHeight: '300px' }}>
                                {userDetails.length > 0 && (
                                    userDetails.map((user) => {
                                        return <p style={{ width: 'fit-content', height: 'fit-content', padding: '0.2rem 0.2rem 1rem 0.2rem'}}>
                                                <span onClick={(event) => { handleChatOpen("maintask", props.data.tasks, [], user )}} 
                                                    style={{ backgroundColor: generateColor(), padding: '0.5rem', borderRadius: '5px', color: 'white', fontWeight: 600 }}>{user.label}</span>
                                               </p>
                                    })
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>)
            }
            {
               open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleChatClose} data={cardInfo} handleModalClose={handleChatClose}
               /> : null
             }
        </div >
    );
}