import { CONSOLIDATED_TODO_TO, CONSOLIDATED_TODO_BY, PRIVATE_TODO, IS_LOADING, IS_LOADED, ALL_TASKS } from "./actions";

export const initialState = {
    consolidatedToDoTo: [],
    consolidatedToDoBy: [],
    privateToDo: [],
    allTasks: []
};
export function consolidatedToDoReducer(state = initialState, action) {

    switch (action.type) {
        case CONSOLIDATED_TODO_TO:
            return {
                ...state,
                consolidatedToDoTo: action.payload
            };
        case CONSOLIDATED_TODO_BY:
            return {
                ...state,
                consolidatedToDoBy: action.payload
            };
        case PRIVATE_TODO:
            return {
                ...state,
                privateToDo: action.payload
            };
        case ALL_TASKS:
            return {
                ...state,
                allTasks: action.payload
            };
        case IS_LOADING:
            return { ...state, isLoading: true };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
