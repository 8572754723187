import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";
//adding userstory to kanban
export async function addToKanban(currentDate, sprintTargetDate, targetDate, taskId, sprintId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, player_id, storyPoints, MODULE, MAINTASK, story_us_id, story_type, main_task_id) {

  if (story_type === 'group' && main_task_id !== '0') {
    Alert('warning', 'Group Task cannot be moved individually into timeline');
  } else {
    const message = `This ${MAINTASK} added in sprint ${(getUser.corp).substring(0, 3).toUpperCase()} - ${sprintDesc}`
    dispatch(isLoading());
    if (targetDate < currentDate && targetDate !== '') {
      Alert('warning', 'Select valid date');

    }
    else if (targetDate > sprintTargetDate && targetDate !== '') {
      Alert('warning', `Target Date should not exceed  ${MODULE} target date`);
    }
    else {

      try {
        var response = await API.post("getUpdateSprint.php", {
          crop: getUser.corp,
          action: "sprint_update",
          sprintId: sprintId,
          userStoryId: taskId,
          targetDate: (targetDate === '') ? sprintTargetDate : targetDate,
          "projectId": getUser.projectId,
          device_id: device_id === null ? state.device_id : device_id,
          player_id: player_id === null ? state.player_id : player_id,
          sprintDesc: sprintDesc,
          assign_to: assign_to === null ? state.userSelected : assign_to,
          emp_id: getUser.empId,
          title: title,
          storyPoints: storyPoints === '0' ? state.storySelected : storyPoints,
          story_us_id: story_us_id
        }, {}, false);
        if (response.status === 'True') {
          if (story_type === 'group' && main_task_id === '0') {
            getGroupTaskInfo(currentDate, sprintTargetDate, targetDate, taskId, sprintId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, player_id, storyPoints, MODULE, MAINTASK, story_us_id, story_type, main_task_id)
          }
          addCommentUserStory(dispatch, getUser, taskId, message, "11");

          Alert("success", "UPDATED");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    }
  }
}

async function getGroupTaskInfo(currentDate, sprintTargetDate, targetDate, taskId, sprintId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, player_id, storyPoints, MODULE, MAINTASK, story_us_id, story_type, main_task_id) {
  try {
    var response = await API.post("getUpdateSprint.php", {
      story_us_id: story_us_id,
      action: "get_group_task_info",
    }, {}, false);
    if (response.status === 'True') {
      response.data.map(item => {
        return moveGroupTasks(currentDate, sprintTargetDate, targetDate, item.story_id, sprintId, state, dispatch, getUser, handleClose, device_id, item.assigned_to, sprintDesc, title, player_id, storyPoints, MODULE, MAINTASK, story_us_id, story_type, main_task_id)
      })
    }
  } catch (error) {
    Alert('error', error.message);
  }
  handleClose()
}

async function moveGroupTasks(currentDate, sprintTargetDate, targetDate, taskId, sprintId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, player_id, storyPoints, MODULE, MAINTASK, story_us_id, story_type, main_task_id) {
  const message = `This ${MAINTASK} added in sprint ${(getUser.corp).substring(0, 3).toUpperCase()} - ${sprintDesc}`
  try {
    var response = await API.post("getUpdateSprint.php", {
      crop: getUser.corp,
      action: "sprint_update",
      sprintId: sprintId,
      userStoryId: taskId,
      targetDate: (targetDate === '') ? sprintTargetDate : targetDate,
      "projectId": getUser.projectId,
      device_id: device_id === null ? state.device_id : device_id,
      player_id: player_id === null ? state.player_id : player_id,
      sprintDesc: sprintDesc,
      assign_to: assign_to === null ? state.userSelected : assign_to,
      emp_id: getUser.empId,
      title: title,
      storyPoints: storyPoints === '0' ? state.storySelected : storyPoints,
      story_us_id: story_us_id
    }, {}, false);
    if (response.status === 'True') {
      addCommentUserStory(dispatch, getUser, taskId, message, "11");
    }
  } catch (error) {
    dispatch(isLoaded());
  }
  handleClose()
}