import moment from "moment";

export default function convertPSTtoLocalTime(time) {
    if (!time) {
        return '';
    }

    // Local time zone (system timezone)
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    // Convert the input time to a Moment.js object in PST timezone
    const pstMoment = moment.tz(time, 'America/Los_Angeles'); // 'America/Los_Angeles' is the IANA timezone identifier for PST

    // Convert the PST time to the local time zone
    const localTime = pstMoment.clone().tz(timeZone);

    // Format the local time as desired (MM/DD/YYYY hh:mm:ss a z)
    return localTime.format("MM/DD/YYYY hh:mm:ss a z");
}

