import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../Alert";
import API from "../../Network/API";
import moment from "moment";

//For Delegate MainTask
export async function delegateMainTask(targetDate, state, dispatch, getUser, ideaId, moduleId, handleClose, MODULE, userInfo) {
  dispatch(isLoading());
  var date = moment();
  var currentDate = date.format('YYYY-MM-D');
  const currentPSTDate = currentDate.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
  });
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
      try {
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          action: "reassign",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          acceptanceCriteria: state.acceptanceCriteria.value,
          module_id: state.moduleId.value,
          idea_id: state.ideaId.value,
          added_to: userInfo.value,
          added_by: getUser.empId,
          mainTaskId: state.taskId.value,
          storyPoints: state.storyPoints.value,
          priorityLevel:  state.priorityLevel.value,
          "projectId":getUser.projectId,
          targetDate: state.targetDate.value || currentPSTDate,
          device_id: userInfo.device_id,
          player_id: userInfo.player_id,
  
        }, {}, false);
        if (response.status === 'True') {
          addCommentUserStory(dispatch, getUser, state.taskId.value, `Task delegate to ${userInfo.label}`, "5")
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    } else {
      Alert("warning", "please give all the details")
    }
  }