import API from "../../Network/API";
import { isLoading, isLoaded, activeSprint,events } from "../action";
import Alert from '../../Alert';
import { Client_URL } from "../../config";


//to get event
export async function getEvent(getUser,dispatch, APP_NAME) {
  dispatch(isLoading());
  // console.log(getUser.corp)
  try {
    var response = await API.post("calendar.php", {
      action: "GetEvent",
      corp_code: getUser.corp,
      projectId: getUser.projectId,
      "userType": getUser.role,
      userName: getUser.userName,
      url: Client_URL,
      appName: APP_NAME,
  }, {}, false);
  // dispatch(user(response.data));
  // console.log(response)
  if (response.status === 'True') {
    // console.log(response.data)
    dispatch(events(response.data))
  }
  else {
    dispatch(events([]))
  }
  } catch (error) {
      console.log(error)
      Alert('error', error.message);
  }
  dispatch(isLoaded());
}

//for getting active sprint id
export async function getActiveSprints(dispatch, getUser) {
  dispatch(isLoading());
  try {
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 start
    // var response = await API.post("getSprints.php", {
    //   "crop": getUser.corp,
    //   "userType": getUser.role,
    //   "empId": getUser.empId,
    //   "action": "getActiveSprint"
    // }, {}, false);
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 end

    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 start
    var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp,
      "action": "get_sprints",
      "projectId": getUser.projectId
    }, {}, false);
    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 end
    if (response.status === 'True') {
      dispatch(activeSprint(response.data[0]))
    }
    else {
      // getUnactiveSprintTasks(dispatch, getUser)
      dispatch(activeSprint([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}